const ArticlesActions = Object.freeze({
  // action types
  INSERT_ARTICLE: "insertNewArticle",
  UPDATE_ARTICLE: "updateArticle",
  UPDATE_STATUS: "updateArticleStatus",
  SAVE_ARTICLE: "saveArticle",
  REMOVE_ARTICLE: "removeArticle",
  LOAD_ARTICLES: "loadArticles",
  LOAD_ARTICLE: "loadArticle",
  VIEW_ARTICLE: "showArticle",
  NOTIFY_ERRORS: "notifyErrors",
});
const ArticlesMutations = Object.freeze({
  // action types
  NEW_ARTICLE: "newArticle",
  EDIT_ARTICLE: "editArticle",
  DELETE_ARTICLE: "deleteArticle",
  SHOW_ARTICLE: "showArticle",
  LIST_ARTICLES: "listArticles",
  SET_ERRORS: "errors",
});

export { ArticlesActions, ArticlesMutations };
