const messages = {
  en: {
    dashboard: "Dashboard",
    close: 'Close',
    download: "Download",
    endpoint: "Endpoint",
    channel: "Channel",
    genders: {
      m: 'Male',
      f: 'Female',
    },
    seo: {
      meta: {
        title: 'SEO - Title',
        description: 'SEO - Description'
      }
    },
    filter: {
      textFilter: 'Filter',
      dates: {
        title: 'Dates',
        dateFrom: 'From',
        dateTo: 'Until',
        createdFrom: 'Created from',
        createdTo: 'Created until',
        moderatedFrom: 'Moderated from',
        moderatedTo: 'Moderated until'
      },
      values: {
        totalFrom: "Total from",
        totalTo: "Total until",
      },
      filterButton: 'Filter',
    },
    aside: {
      menu: 'Menu',
      users: {
        title: 'Users',
        administrators: 'Administrators',
        psychologists: 'Psychologists',
        customers: 'Customers',
        specialties: {
          title: 'Specialties',
        },
        localizations: {
          title: 'Locations',
        },
        testimonials: {
          title: 'Testimonials'
        },
      },
      translations: 'Translations',
      paymentModes: 'Payment modes',
      partners: 'Partners',
      params: 'Params',
      notifications: 'Notifications',
      socialLinks: 'Social links',
      installations: 'Installations',
      vouchers: 'Vouchers',
      orders: 'Sales',
      appointments: 'Appointments',
      articles: 'Articles',
      pages: 'Pages',
      menus: 'Menus',
    },
    signIn: "Sign-in",
    password: "Password",
    password_confirmation: "Password confirmation",
    password_match: "Passwords must match",
    forgotPassword: "Forgot password?",
    passwordReset: "Password Reset",
    email: "Email",
    submit: "Continue",
    wait: "Please wait...",
    enterYourEmail: "Enter your email to reset your password.",
    cancel: "Cancel",
    date: 'Date',
    dateOrder: 'Order Date',
    dateAppointment: 'Appointment Date',
    name: 'Name',
    value: 'Value',
    slug: 'Slug',
    title: 'Title',
    description: 'Description',
    phone: 'Phone',
    timezone: 'Timezone',
    url: 'URL',
    pageText: 'Page Text',
    content: 'Content',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    installation: 'Installation',
    actions: 'Actions',
    search: 'Search',
    deleteSelected: 'Delete selected',
    activateSelected: 'Activate selected(s)',
    inactivateSelected: 'Inactivate selected(s)',
    selected: 'Selected(s)',
    select: 'Select',
    selectInstallation: 'Select one installation',
    image: 'Image',
    avatar: 'Avatar',
    seeContent: 'See content',
    access: 'Access',
    remove: 'Remove',
    edit: 'Edit',
    duplicate: 'Duplicate',
    configure: 'Configure',
    enabled: 'Enabled',
    disabled: 'Disabled',
    accepted: 'Accepted',
    rejected: 'Rejected',
    enable: 'Enable',
    disable: 'Disable',
    export: 'Export',
    areYouSure: 'Are you sure?',
    wannaRemove: 'Are you sure would like remove this item?',
    wannaCancel: 'Are you like cancel this action?',
    wannaSelectDate: 'Are you like to select this date?',
    wannaUpdateAppointmentDate: 'Are you like to update this appointment date?',
    wannaLogout: 'Are you sure would like sign out all sessions?',
    wannaInsert: 'Are you sure would like insert this item?',
    wannaUpdate: 'Are you sure would like update this item?',
    wannaUpdateStatus: 'Are you sure would like update the status of this item?',
    wannaDuplicate: 'Are you sure would like duplicate this item?',
    wannaBatchStatus: 'Are you sure would like batch change status this item(s)?',
    wannaBatchDelete: 'Are you sure would like batch remove this item(s)?',
    yesLogout: 'Yes, I wanna logout.',
    yes: 'Yes',
    no: 'Não',
    chooseOrDropFile: 'Choose a file or drop it here...',
    dropFile: 'Drop file here...',
    activate: 'Activate',
    saveChanges: 'Save changes',
    saveNew: 'Salvar & New',
    saveEdit: 'Salvar & Edit',
    saveDuplicate: 'Salvar & Duplicate',
    batchActions: 'Batch actions',
    languages: 'Languages',
    chooseInstallation: 'Choose the installation',
    chooseInstallations: 'Choose the installations',
    choosePaymentMethods: 'Choose the payment methods',
    chooseRoles: 'Choose the role',
    chooseType: 'Choose the type',
    role: 'Role',
    type: 'Type',
    chooseLanguages: 'Choose the languages',
    reorderItems: 'Reorder items',
    ok: 'ok',
    total: 'Total',
    commission: 'Commission',
    voucher: 'Voucher',
    orderNumber: 'Order',
    appointments: {
      statusTypes: {
        available: 'Available',
        reserved: 'Reserved',
        scheduled: 'Scheduled',
        done: 'Done',
        canceled: 'Canceled',
      },
      customer: 'Customer',
      psychologist: 'Psychologist',
      chatroom: 'Chatroom',
      cancellationReason: 'Cancellation Reason',
      cancellationUser: 'Cancellation User',
      order: 'Order',
      installation: 'Installation',
      date: 'Date',
      time: 'Time',
      started_at: 'Started at',
      finished_at: 'Finished at',
      amount: 'Value',
      voucher: 'Voucher',
      payment: 'Payment',
      revenue_share: 'Revenue Share',
    },
    order: {
      total: 'Total',
      status: 'Status',
      discount: 'Discount',
      paid: 'Paid',
      toPay: 'To pay',
      paidAt: 'Paid at',
      lastTransaction: 'Last Transaction',
      payment: 'Payment',
      transaction: 'Transaction',
      document: 'Document',
      statusTypes: {
        pending: 'Pending',
        paid: 'Paid',
        refunded: 'Refunded',
        canceled: 'Canceled',
        failed: 'Failed',
      },
    },
    articles: {
      newArticle: 'New Article',
      slug: "Slug",
      name: "Name",
      content: "Content",
      summary: 'Summary',
      description: 'Description',
      url: 'URL',
      active: 'Active',
      editArticle: 'Edit article {name}',
      duplicateArticle: 'Duplicate article {name}',
      insertArticle: 'Yes, insert the article {name}',
      removeArticle: 'Yes, remove the article {name}',
      successInsert: 'Article inserted successfully',
      successUpdate: 'Article updated successfully',
      successDuplicate: 'Article duplicated successfully',
      successRemoved: 'Article removed successfully',
      required: 'The article is required',
      requiredName: 'The name of the article is required'
    },
    specialties: {
      attach: {
        title: 'Associate Specialties',
        select: 'Select specialties',
        send: 'Send',
        messages: {
          errors: {
            noSpecialty: 'The specialty is required',
          }
        }
      },
      name: 'Name',
      description: 'Description',
      newSpecialty: 'New Specialty',
      editSpecialty: 'Edit specialty {name}',
      duplicateSpecialty: 'Duplicate specialty {name}',
      insertSpecialty: 'Yes, insert the specialty {name}',
      removeSpecialty: 'Yes, remove the specialty {name}',
      successAttach: 'Specialty attached successfully',
      successInsert: 'Specialty inserted successfully',
      successUpdate: 'Specialty updated successfully',
      successDuplicate: 'Specialty duplicated successfully',
      successRemoved: 'Specialty removed successfully',
      required: 'The specialty is required',
      requiredName: 'The name of the specialty is required'
    },
    localizations: {
      newLocalization: 'New Location',
      editLocalization: 'Edit location {name}',
      duplicateLocalization: 'Duplicate location {name}',
      insertLocalization: 'Yes, insert the location {name}',
      removeLocalization: 'Yes, remove the location {name}',
      successInsert: 'Location inserted successfully',
      successUpdate: 'Location updated successfully',
      successDuplicate: 'Location duplicated successfully',
      successRemoved: 'Location removed successfully',
      required: 'The location is required',
      requiredName: 'The name of the location is required',
      errorNoInstallations: 'The installations are required'
    },
    notifications: {
      subject: 'Subject',
      bodySms: 'Content SMS',
      newNotification: 'New Notification',
      editNotification: 'Edit notification {name}',
      duplicateNotification: 'Duplicate notification {name}',
      insertNotification: 'Yes, insert the notification {name}',
      removeNotification: 'Yes, remove the notification {name}',
      successInsert: 'Notification inserted successfully',
      successUpdate: 'Notification updated successfully',
      successDuplicate: 'Notification duplicated successfully',
      successRemoved: 'Notification removed successfully',
      required: 'The notification is required',
      requiredName: 'The name of the notification is required',
      errorNoInstallations: 'The installations are required'
    },
    translations: {
      key: 'Key',
      group: 'Group',
      locale: 'Locale',
      value: 'Content',
      newTranslation: 'New Translation',
      editTranslation: 'Edit translation {key}',
      duplicateTranslation: 'Duplicate translation {key}',
      insertTranslation: 'Yes, insert the translation {key}',
      removeTranslation: 'Yes, remove the translation {key}',
      successInsert: 'Translation inserted successfully',
      successUpdate: 'Translation updated successfully',
      successDuplicate: 'Translation duplicated successfully',
      successRemoved: 'Translation removed successfully',
      required: 'The translation is required',
      requiredKey: 'The name of the translation is required',
      requiredGroup: 'The name of the translation is required',
      errorNoInstallations: 'The installations are required'
    },
    users: {
      appointments: {
        cancellationReason: 'Cancellation reason',
        buttonCancelAppointment: 'Cancel Appointment',
        reasonRequired: 'The cancellation reason is required',
        wannaCancel: 'Are you sure you want to cancel this appointment? This action cannot be reversed.',
      },
      type: {
        label: 'User type',
        admin: 'Admin',
        customer: 'Customer',
        psychologist: 'Psychologist'
      },
      ogId: 'OG #{id}',
      role: 'Profile',
      roles: 'Profiles',
      optionalPassword: 'If you don\'t want update your password, leave this blank',
      newUser: 'New User',
      editUser: 'Edit user {name}',
      duplicateUser: 'Duplicate user {name}',
      insertUser: 'Yes, insert the user {name}',
      removeUser: 'Yes, remove the user {name}',
      successInsert: 'User inserted successfully',
      successUpdate: 'User updated successfully',
      successDuplicate: 'User duplicated successfully',
      successRemoved: 'User removed successfully',
      required: 'The user is required',
      requiredName: 'The name of the user is required',
      allowSms: 'Allow SMS notification',
      allowEmail: 'Allow email notification',
      isOnline: 'This user can access online platform',
      provider: 'Provider',
      providerId: 'Provider ID',
      phonePrefix: 'Phone prefix',
      phoneCountry: 'Phone Country',
      schedules: {
        title: 'Schedule an appointment',
        newSchedule: {
          title: 'New Appointment',
          form: {
            installation: 'Installation',
            customer: 'Customer',
            date: 'Choose an available date',
            payment: 'Payment Mode',
            paymentMethod: 'Payment Method',
            voucher: 'Voucher',
            price: 'Price',
            location: 'Location',
          },
          wannaCreate: 'Are you sure would like schedule this appointment?',
          success: 'Appointment sent successfully',
        },
        save: 'Save',
      },
      info: {
        personalInfo: "Personal Info",
        testimonials: 'Testimonials',
        schedules: "Schedules",
        clinicalInfo: "Clinical Info",
        psychologistInfo: "Phycologist Info",
        psychologistPayments: "Payments",
      },
      dateOrder: "Order Date",
      clinical: {
        vatNumber: 'Vat Number',
        preferredPsychologist: "Preferred Psychologist",
        birthDate: "Birth date",
        gender: "Gender",
        academicFormation: "Academic Formation",
        profession: "Profession",
        labourSituation: "Labour Situation",
        maritalStatus: "Marital Status",
        children: "Children",
        numberHousehold: "Number",
        constitutionHousehold: "Address",
        country: "Country Code (Ex: PRT)",
        cityResidence: "City",
        motiveSchedule: "Motive Schedule",
        ancestorsMentalHealth: "Ancestors Mental Health",
        mentalDisease: "Mental Disease",
        ancestorsPhysicalDisease: "Ancestors Physical Disease",
      },
      profile: {
        title: 'Information',
        revenueShare: 'Revenue Share',
        about: 'About',
        experience: 'Experience',
        formation: 'Formation',
        whyJoin: 'Why Join?',
        extraActivity: 'Extra Activity',
        availabilitySOS: 'Availability SOS',
        availability: 'Availability',
        yearsExperience: 'Years Experience',
        certifiedNr: 'Certified Nr.',
        cv: 'Curriculum Vitae',
        order: 'Order',
        specialties: {
          title: 'Specialties',
        }
      },
      report: {
        label: 'Report',
        symptoms: 'Symptoms',
        scheduleNotes: 'Schedule Notes',
        TPC: 'TPC',
        finalConsiderations: 'Final Considerations',
        therapeuticProposal: 'Therapeutic Proposal',
        notes: 'Notes',
        diagnosticSymptomatology: 'Diagnostic Symptomatology',
        motive: 'Motive',
        observationHumor: 'Observation Humor',
        observationBehaviour: 'Observation Behaviour',
        observationMentalState: 'Observation Mental State',
        anamnesis: 'Anamnesis',
        speech: 'Speech',
        usualMedication: 'Usual Medication',
        scheduleMotive: 'Schedule Motive',
        followUp: 'Follow Up'
      },
      emitPayment: 'Emit Payment',
      orders: {
        wannaPay: 'Are you sure would like pay this psychologist?',
        successUpdate: 'Payment successful',
      },
      payments: {
        title: 'Payments',
        total: 'Total',
        administrator: 'Admin',
        paymentDate: 'Payment Date',
        wannaChangeRevenueShare: 'Are you sure would like update this commission?',
        currentAmount: 'Current Amount',
        currentRevenueShare: 'Current Revenue Share',
        newAmount: 'New amount',
        reason: 'Update reason',
        lines: {
          orderNumber: '#Order',
          createdAt: 'Created At',
          updatedAt: 'Updated At',
          amount: 'Amount',
        },
        revenueShare: {
          title: "Revenue Shares",
          updateRevenueShare: 'Update Revenue Share',
          history: {
            title: 'History',
            administrator: 'Admin',
            orderNumber: "#Order",
            appointmentDate: 'Appointment Date',
            orderAmount: 'Order Amount',
            psychologistRevenueShare: 'Psychologist Revenue Share',
            appliedRevenueShare: 'Applied Revenue Share',
            updateReason: 'Update Reason',
          }
        }
      },
      testimonials: {
        title: "Testemunhos",
        moderate: 'Moderate',
        moderatedAt: 'Moderated at',
        createdAt: 'Date',
        rating: 'Rating',
        successUpdate: 'Testimonial moderated successfully'
      }
    },
    vouchers: {
      newVoucher: 'New Voucher',
      editVoucher: 'Edit voucher {name}',
      duplicateVoucher: 'Duplicate voucher {name}',
      insertVoucher: 'Yes, insert the voucher {name}',
      removeVoucher: 'Yes, remove the voucher {name}',
      successInsert: 'Voucher inserted successfully',
      successUpdate: 'Voucher updated successfully',
      successDuplicate: 'Voucher duplicated successfully',
      successRemoved: 'Voucher removed successfully',
      required: 'The voucher is required',
      requiredName: 'The name of the voucher is required',
      utilizationCount: 'Qty Use',
      typeTransaction: {
        label: 'Coupon type',
        discountAbsolute: 'Discount €',
        discountPercentage: 'Discount %',
        free: 'Free',
        pay: 'Final value'
      },
      value: 'Value',
      codeCoupon: 'Coupon code',
      qtyUse: 'Max uses',
      dateUntil: 'Valid until',
    },
    pages: {
      newPage: 'New Page',
      editPage: 'Edit page {name}',
      duplicatePage: 'Duplicate page {name}',
      insertPage: 'Yes, insert the page {name}',
      removePage: 'Yes, remove the page {name}',
      successInsert: 'Page inserted successfully',
      successUpdate: 'Page updated successfully',
      successDuplicate: 'Page duplicated successfully',
      successRemoved: 'Page removed successfully',
      required: 'The page is required',
      requiredName: 'The name of the page is required'
    },
    menus: {
      updateOrder: 'Are you sure would you like reorder this items?',
      column: 'Column',
      seeItems: 'See items',
      items: 'Items',
      order: 'Order',
      url: 'URL',
      urlType: 'URL Type',
      page: 'Page',
      parentMenu: 'Parent menu',
      newMenu: 'New Menu',
      editMenu: 'Edit menu {name}',
      duplicateMenu: 'Duplicate menu {name}',
      insertMenu: 'Yes, insert the menu {name}',
      removeMenu: 'Yes, remove the menu {name}',
      successInsert: 'Menu inserted successfully',
      successUpdate: 'Menu updated successfully',
      successDuplicate: 'Menu duplicated successfully',
      successRemoved: 'Menu removed successfully',
      required: 'The menu is required',
      requiredName: 'The name of the menu is required',
      noParentMenu: 'No parent',
    },
    partners: {
      newPartner: 'New Partner',
      editPartner: 'Edit partner {name}',
      duplicatePartner: 'Duplicate partner {name}',
      insertPartner: 'Yes, insert the partner {name}',
      removePartner: 'Yes, remove the partner {name}',
      successInsert: 'Partner inserted successfully',
      successUpdate: 'Partner updated successfully',
      successDuplicate: 'Partner duplicated successfully',
      successRemoved: 'Partner removed successfully',
    },
    params: {
      newParam: 'New Param',
      editParam: 'Edit param {name}',
      duplicateParam: 'Duplicate param {name}',
      insertParam: 'Yes, insert the param {name}',
      removeParam: 'Yes, remove the param {name}',
      successInsert: 'Param inserted successfully',
      successUpdate: 'Param updated successfully',
      successDuplicate: 'Param duplicated successfully',
      successRemoved: 'Param removed successfully',
    },
    payments: {
      wannaChangeRevenueShare: 'Tens certeza que queres alterar o valor da comissão?',
      daysToExpire: 'Expire days',
      methodList: {
        multibanco: {
          title: 'Multibanco',
        },
        payshop: {
          title: 'Payshop',
        },
        mbWay: {
          title: 'MBWay',
        },
        ccard: {
          title: 'Credit card',
        },
      },
      newPayment: 'New Payment',
      path: 'Path',
      methods: 'Methods',
      editPayment: 'Edit payment {name}',
      duplicatePayment: 'Duplicate payment {name}',
      insertPayment: 'Yes, insert the payment {name}',
      removePayment: 'Yes, remove the payment {name}',
      successInsert: 'Payment inserted successfully',
      successUpdate: 'Payment updated successfully',
      successDuplicate: 'Payment duplicated successfully',
      successRemoved: 'Payment removed successfully',
    },
    installations: {
      label: 'Installations',
      countryCode: 'Code',
      countryDescription: 'Description',
      languageCode: 'Language Code',
      languageDescription: 'Language',
      appUrl: 'Front-office URL',
      officegest: {
        title: 'Officegest',
        officegestUrl: 'Officegest URL',
        officegestUser: 'Officegest User',
        officegestPassword: 'Officegest Pass',
        defaultArticleCode: 'Default Article Code',
        documentSeries: 'Document Series',
        documentType: 'Document Type',
        documentStatus: {
          title: 'Document Status',
          drafts: 'Drafts',
          documents: 'Document'
        },
        defaultGenericArticleCode: 'Default Generic Article Code',
      },
      pbx: 'PBX',
      newInstallation: 'New Installation',
      editInstallation: 'Edit installation {country_description}',
      duplicateInstallation: 'Duplicate installation {country_description}',
      insertInstallation: 'Yes, insert the installation {country_description}',
      removeInstallation: 'Yes, remove the installation {country_description}',
      successInsert: 'Installation inserted successfully',
      successUpdate: 'Installation updated successfully',
      successDuplicate: 'Installation duplicated successfully',
      successRemoved: 'Installation removed successfully',
    },
    socialLinks: {
      newSocialLink: 'New Social Link',
      editSocialLink: 'Edit social link {description}',
      duplicateSocialLink: 'Duplicate social link {description}',
      insertSocialLink: 'Yes, insert the social link {description}',
      removeSocialLink: 'Yes, remove the social link {description}',
      successInsert: 'Social link inserted successfully',
      successUpdate: 'Social link updated successfully',
      successDuplicate: 'Social link duplicated successfully',
      successRemoved: 'Social link removed successfully',
    },
  },
  pt_PT: {
    dashboard: "Dashboard",
    close: 'Fechar',
    download: "Download",
    endpoint: "Endpoint",
    channel: "Canal",
    genders: {
      m: 'Masculino',
      f: 'Feminino',
    },
    seo: {
      meta: {
        title: 'SEO - Título',
        description: 'SEO - Descrição'
      }
    },
    filter: {
      textFilter: 'Filtro',
      dates: {
        title: 'Datas',
        dateFrom: 'De',
        dateTo: 'Até',
        createdFrom: 'Criado de',
        createdUntil: 'Criado até',
        moderatedFrom: 'Moderado de',
        moderatedUntil: 'Moderado até'
      },
      values: {
        totalFrom: "Total de",
        totalTo: "Total até",
      },
      filterButton: 'Filtrar',
    },
    aside: {
      menu: 'Menu',
      users: {
        title: 'Utilizadores',
        administrators: 'Administradores',
        psychologists: 'Psicólogos',
        customers: 'Clientes',
        specialties: {
          title: 'Competéncias',
        },
        localizations: {
          title: 'Locais',
        },
        testimonials: {
          title: 'Testemunhos'
        },
      },
      translations: 'Traduções',
      paymentModes: 'Modos de pagamento',
      partners: 'Parceiros',
      params: 'Parámetros',
      notifications: 'Notificações',
      socialLinks: 'Redes sociais',
      installations: 'Instalações',
      vouchers: 'Vouchers',
      orders: 'Vendas',
      articles: 'Artigos',
      pages: 'Páginas',
      menus: 'Menus',
    },
    signIn: "Inicie a sessão",
    password: "Palavra-passe",
    password_confirmation: "Confirmação",
    password_match: "Palavras-passe precisam ser idênticas",
    forgotPassword: "Esqueceu-se da palavra-passe?",
    passwordReset: "Nova palavra-passe",
    email: "Email",
    submit: "Submeter",
    wait: "Aguarde...",
    enterYourEmail: "Entre com seu email para registar nova palavra-passe",
    cancel: "Cancelar",
    date: 'Data',
    dateOrder: 'Data da compra',
    dateAppointment: 'Data da consulta',
    name: 'Nome',
    value: 'Valor',
    slug: 'Slug',
    title: 'Título',
    description: 'Descrição',
    phone: 'Telefone',
    timezone: 'Fuso',
    url: 'URL',
    pageText: 'Texto',
    content: 'Conteúdo',
    status: 'Status',
    active: 'Ativo',
    inactive: 'Inativo',
    installation: 'Instalação',
    actions: 'Acções',
    search: 'Pesquisar',
    deleteSelected: 'Remover selecionado',
    activateSelected: 'Activar selecionado(s)',
    inactivateSelected: 'Inactivar selecionado(s)',
    selected: 'Selecionado(s)',
    select: 'Selecione',
    selectInstallation: 'Selecione uma instalação',
    image: 'Imagem',
    avatar: 'Avatar',
    seeContent: 'Ver conteúdo',
    access: 'Acessar',
    remove: 'Remover',
    edit: 'Editar',
    duplicate: 'Duplicar',
    configure: 'Configurar',
    enabled: 'Activo',
    disabled: 'Inactivo',
    accepted: 'Aceito',
    rejected: 'Rejeitado',
    enable: 'Activar',
    disable: 'Desactivar',
    export: 'Exportar',
    areYouSure: 'Estás certo disso?',
    wannaRemove: 'Queres remover este item?',
    wannaCancel: 'Queres cancelar esta acção?',
    wannaSelectDate: 'Queres selecionar esta data?',
    wannaUpdateAppointmentDate: 'Queres atualizar a data desta consulta?',
    wannaLogout: 'Queres desconectar?',
    wannaInsert: 'Queres inserir este item?',
    wannaUpdate: 'Queres actualizar este item?',
    wannaUpdateStatus: 'Queres actualizar o status deste item?',
    wannaDuplicate: 'Queres duplicar este item?',
    wannaBatchStatus: 'Queres alterar o status destes itens?',
    wannaBatchDelete: 'Queres remover estes itens?',
    yesLogout: 'Sim, desejo desconectar',
    yes: 'Sim',
    no: 'Não',
    chooseOrDropFile: 'Escolha um ficheiro ou solte aqui',
    dropFile: 'Solte o ficheiro aqui',
    activate: 'Activar',
    saveChanges: 'Guardar alterações',
    saveNew: 'Salvar e Novo',
    saveEdit: 'Salvar e Editar',
    saveDuplicate: 'Salvar e Duplicar',
    batchActions: 'Acções em lote',
    languages: 'Idiomas',
    chooseInstallation: 'Escolha a instalação',
    chooseInstallations: 'Escolha as instalações',
    choosePaymentMethods: 'Escolha os métodos de pagamento',
    chooseRoles: 'Escolha o perfil',
    chooseType: 'Escolha o tipo',
    role: 'Perfil',
    type: 'Tipo',
    chooseLanguages: 'Escolha os idiomas',
    reorderItems: 'Reordenar itens',
    ok: 'ok',
    total: 'Total',
    commission: 'Comissão',
    voucher: 'Voucher',
    orderNumber: 'Ordem',
    appointments: {
      statusTypes: {
        available: 'Disponível',
        reserved: 'Reservada',
        scheduled: 'Agendada',
        done: 'Finalizada',
        canceled: 'Cancelada',
      },
      customer: 'Cliente',
      psychologist: 'Psicólogo',
      chatroom: 'Consultório online',
      cancellationReason: 'Motivo do cancelamento',
      cancellationUser: 'Cancelada por',
      order: 'Venda',
      installation: 'Instalação',
      date: 'Data',
      time: 'Hora',
      started_at: 'Iniciada em',
      finished_at: 'Finalizada em',
      amount: 'Valor',
      voucher: 'Cupão',
      payment: 'Pagamento',
      revenue_share: 'Comissão',
    },
    order: {
      total: 'Total',
      status: 'Status',
      discount: 'Desconto',
      paid: 'Pago',
      toPay: 'A pagar',
      paidAt: 'Pago em',
      lastTransaction: 'Última transação',
      payment: 'Pagamento',
      transaction: 'Transação',
      document: 'Documento',
      statusTypes: {
        pending: 'Pendente',
        paid: 'Paga',
        refunded: 'Devolvida',
        canceled: 'Cancelada',
        failed: 'Falha',
      },
    },
    articles: {
      newArticle: "Novo artigo",
      slug: "Slug",
      name: "Nome",
      content: "Conteúdo",
      summary: 'Resumo',
      description: 'Descrição',
      url: 'URL',
      active: 'Ativo',
      editArticle: "Editar artigo {name}",
      duplicateArticle: "Duplicar artigo {name}",
      insertArticle: 'Sim, adicione o artigo {name}',
      removeArticle: 'Sim, remova o artigo {name}',
      successInsert: 'Artigo inserido com sucesso',
      successUpdate: 'Artigo actualizado com sucesso',
      successDuplicate: 'Artigo duplicado com sucesso',
      successRemoved: 'Artigo removido com sucesso',
      required: 'O artigo é obrigatório',
      requiredName: 'O nome do artigo é obrigatório'
    },
    specialties: {
      attach: {
        title: 'Atribuir especialidades',
        select: 'Selecione as especialidades',
        send: 'Enviar',
        messages: {
          errors: {
            noSpecialty: 'A especialidade é obrigatória',
          }
        }
      },
      name: 'Nome',
      description: 'Descrição',
      newSpecialty: "Nova competéncia",
      editSpecialty: "Editar competéncia {name}",
      duplicateSpecialty: "Duplicar competéncia {name}",
      insertSpecialty: 'Sim, adicione a competéncia {name}',
      removeSpecialty: 'Sim, remova a competéncia {name}',
      successAttach: 'Competéncias atribuídas com sucesso',
      successInsert: 'Competéncia inserida com sucesso',
      successUpdate: 'Competéncia actualizada com sucesso',
      successDuplicate: 'Competéncia duplicada com sucesso',
      successRemoved: 'Competéncia removida com sucesso',
      required: 'A competéncia é obrigatório',
      requiredName: 'O nome da competéncia é obrigatório'
    },
    localizations: {
      newLocalization: "Nova localização",
      editLocalization: "Editar localização {name}",
      duplicateLocalization: "Duplicar localização {name}",
      insertLocalization: 'Sim, adicione a localização {name}',
      removeLocalization: 'Sim, remova a localização {name}',
      successInsert: 'Localização inserida com sucesso',
      successUpdate: 'Localização actualizada com sucesso',
      successDuplicate: 'Localização duplicada com sucesso',
      successRemoved: 'Localização removida com sucesso',
      required: 'A localização é obrigatório',
      requiredName: 'O nome da localização é obrigatório',
      errorNoInstallations: 'É mandatório selecionar instalações para completar esta ação'
    },
    notifications: {
      subject: 'Subject',
      bodySms: 'Conteúdo SMS',
      newNotification: "Nova notificação",
      editNotification: "Editar notificação {name}",
      duplicateNotification: "Duplicar notificação {name}",
      insertNotification: 'Sim, adicione a notificação {name}',
      removeNotification: 'Sim, remova a notificação {name}',
      successInsert: 'Notificação inserida com sucesso',
      successUpdate: 'Notificação actualizada com sucesso',
      successDuplicate: 'Notificação duplicada com sucesso',
      successRemoved: 'Notificação removida com sucesso',
      required: 'A notificação é obrigatório',
      requiredName: 'O nome da notificação é obrigatório',
      errorNoInstallations: 'É mandatório selecionar instalações para completar esta ação'
    },
    translations: {
      key: 'Chave',
      group: 'Grupo',
      locale: 'Locale',
      value: 'Conteúdo',
      newTranslation: "Nova tradução",
      editTranslation: "Editar tradução {key}",
      duplicateTranslation: "Duplicar tradução {key}",
      insertTranslation: 'Sim, adicione a tradução {key}',
      removeTranslation: 'Sim, remova a tradução {key}',
      successInsert: 'Tradução inserida com sucesso',
      successUpdate: 'Tradução actualizada com sucesso',
      successDuplicate: 'Tradução duplicada com sucesso',
      successRemoved: 'Tradução removida com sucesso',
      required: 'A tradução é obrigatório',
      requiredKey: 'O nome da tradução é obrigatório',
      requiredGroup: 'O nome da tradução é obrigatório',
      errorNoInstallations: 'É mandatório selecionar instalações para completar esta ação'
    },
    users: {
      appointments: {
        cancellationReason: 'Motivo do cancelamento',
        buttonCancelAppointment: 'Cancelar Agendamento',
        reasonRequired: 'O motivo do cancelamento é obrigatório',
        wannaCancel: 'Tens certeza que deseja cancelar este agendamento? Esta acção não poderá ser revertida',
      },
      type: {
        label: 'Tipo',
        admin: 'Admin',
        customer: 'Cliente',
        psychologist: 'Psicólogo'
      },
      ogId: 'OG #{id}',
      role: 'Perfil',
      roles: 'Perfis',
      optionalPassword: "Caso não queiras actualizar a palavra-passe deixe-a em branco",
      newUser: "Novo utilizador",
      editUser: "Editar utilizador {name}",
      duplicateUser: "Duplicar utilizador {name}",
      insertUser: 'Sim, adicione o utilizador {name}',
      removeUser: 'Sim, remova o utilizador {name}',
      successInsert: 'Utilizador inserido com sucesso',
      successUpdate: 'Utilizador actualizado com sucesso',
      successDuplicate: 'Utilizador duplicado com sucesso',
      successRemoved: 'Utilizador removido com sucesso',
      required: 'O utilizador é obrigatório',
      requiredName: 'O nome do utilizador é obrigatório',
      allowSms: 'Permitir notificações via SMS',
      allowEmail: 'Permitir notificações via email',
      isOnline: 'Pode aceder à plataforma online',
      provider: 'Provedor',
      providerId: 'ID do provedor',
      phonePrefix: 'Prefixo do telefone',
      phoneCountry: 'País do telefone',
      schedules: {
        title: 'Agendar consulta',
        newSchedule: {
          title: 'Nova consulta',
          form: {
            installation: 'Instalação',
            customer: 'Cliente',
            date: 'Escolha uma data disponível',
            payment: 'Forma de pagamento',
            paymentMethod: 'Método de pagamento',
            voucher: 'Voucher',
            price: 'Preço',
            location: 'Local',
          },
          wannaCreate: 'Tens certeza que deseja agendar esta consulta?',
          success: 'Marcação de consulta enviada com sucesso',
        },
        save: 'Salvar',
      },
      info: {
        personalInfo: "Pessoal",
        testimonials: 'Testemunhos',
        schedules: "Marcações",
        clinicalInfo: "Informações clínicas",
        psychologistInfo: "Informações psicológicas",
        psychologistPayments: "Pagamentos",
      },
      dateOrder: "Data do pagamento",
      clinical: {
        vatNumber: 'Contribuinte',
        preferredPsychologist: "Psicólogo preferido",
        birthDate: "Data de nascimento",
        gender: "Sexo",
        academicFormation: "Formação académica",
        profession: "Profissão",
        labourSituation: "Situação trabalhista",
        maritalStatus: "Estado civil",
        children: "Children",
        numberHousehold: "Number",
        constitutionHousehold: "Address",
        country: "Código do País (Ex: PRT)",
        cityResidence: "City",
        motiveSchedule: "Motive Schedule",
        ancestorsMentalHealth: "Ancestors Mental Health",
        mentalDisease: "Mental Disease",
        ancestorsPhysicalDisease: "Ancestors Physical Disease",
      },
      profile: {
        title: 'Informações',
        revenueShare: 'Revenue Share',
        about: 'About',
        experience: 'Experience',
        formation: 'Formation',
        whyJoin: 'Why Join?',
        extraActivity: 'Extra Activity',
        availabilitySOS: 'Availability SOS',
        availability: 'Availability',
        yearsExperience: 'Years Experience',
        certifiedNr: 'Certified Nr.',
        cv: 'Curriculum CV',
        order: 'Ordem',
        specialties: {
          title: 'Compatências',
        }
      },
      report: {
        label: 'Report',
        symptoms: 'Symptoms',
        scheduleNotes: 'Schedule Notes',
        TPC: 'TPC',
        finalConsiderations: 'Final Considerations',
        therapeuticProposal: 'Therapeutic Proposal',
        notes: 'Notes',
        diagnosticSymptomatology: 'Diagnostic Symptomatology',
        motive: 'Motive',
        observationHumor: 'Observation Humor',
        observationBehaviour: 'Observation Behaviour',
        observationMentalState: 'Observation Mental State',
        anamnesis: 'Anamnesis',
        speech: 'Speech',
        usualMedication: 'Medication',
        scheduleMotive: 'Schedule Motive',
        followUp: 'Follow Up'
      },
      emitPayment: 'Emitir pagamento',
      orders: {
        wannaPay: 'Tens certeza que queres repassar o valor das consultas?',
        successUpdate: 'Pagamento efetuado com sucesso',
      },
      payments: {
        title: 'Pagamentos',
        total: 'Total',
        administrator: 'Admin',
        paymentDate: 'Data do pagamento',
        wannaChangeRevenueShare: 'Tens certeza que queres alterar o valor da comissão?',
        currentAmount: 'Valor atual',
        currentRevenueShare: 'Comissão atual',
        newAmount: 'Novo valor',
        reason: 'Motivo da alteração',
        lines: {
          orderNumber: '#Pedido',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em',
          amount: 'Valor',
        },
        revenueShare: {
          title: "Comissões",
          updateRevenueShare: 'Atualizar comissão',
          history: {
            title: 'Histórico',
            administrator: 'Admin',
            orderNumber: "Pedido",
            appointmentDate: 'Data da consulta',
            orderAmount: 'Valor',
            psychologistRevenueShare: 'Comissão do psicóligo',
            appliedRevenueShare: 'Comissão aplicada',
            updateReason: 'Motivo da alteração',
          }
        }
      },
      testimonials: {
        title: "Testemunhos",
        moderate: 'Moderar',
        moderatedAt: 'Moderado',
        createdAt: 'Data',
        rating: 'Rating',
        successUpdate: 'Testemunho moderado com sucesso'
      }
    },
    vouchers: {
      newVoucher: "Novo voucher",
      editVoucher: "Editar voucher {cod_coupon}",
      duplicateVoucher: "Duplicar voucher {cod_coupon}",
      insertVoucher: 'Sim, adicione o voucher {cod_coupon}',
      removeVoucher: 'Sim, remova o voucher {cod_coupon}',
      successInsert: 'Voucher inserido com sucesso',
      successUpdate: 'Voucher actualizado com sucesso',
      successDuplicate: 'Voucher duplicado com sucesso',
      successRemoved: 'Voucher removido com sucesso',
      required: 'O voucher é obrigatório',
      requiredName: 'O código do cupão é obrigatório',
      utilizationCount: 'Qtd Util.',
      typeTransaction: {
        label: 'Tipo de cupão',
        discountAbsolute: 'Desconto €',
        discountPercentage: 'Desconto %',
        free: 'Gratuito',
        pay: 'Valor final'
      },
      value: 'Valor',
      codeCoupon: 'Cupão',
      qtyUse: 'Max Util.',
      dateUntil: 'Válido até',
    },
    pages: {
      newPage: "Nova página",
      editPage: "Editar página {name}",
      duplicatePage: "Duplicar página {name}",
      insertPage: 'Sim, adicione a página {name}',
      removePage: 'Sim, remova a página {name}',
      successInsert: 'Página inserida com sucesso',
      successUpdate: 'Página actualizada com sucesso',
      successDuplicate: 'Página duplicada com sucesso',
      successRemoved: 'Página removida com sucesso',
      required: 'A página é obrigatória',
      requiredName: 'O nome da página é obrigatória'
    },
    menus: {
      updateOrder: 'Tens certeza que queres reordenar os itens?',
      column: 'Coluna',
      seeItems: 'Ver itens',
      items: 'Itens',
      order: 'Ordem',
      url: 'URL',
      urlType: 'Tipo de URL',
      page: 'Página',
      parentMenu: 'Menu pai',
      newMenu: "Novo item de menu",
      editMenu: "Editar item de menu {name}",
      duplicateMenu: "Duplicar item de menu {name}",
      insertMenu: 'Sim, adicione o item de menu {name}',
      removeMenu: 'Sim, remova o item de menu {name}',
      successInsert: 'Item de menu inserido com sucesso',
      successUpdate: 'Item de menu actualizado com sucesso',
      successDuplicate: 'Item de menu duplicado com sucesso',
      successRemoved: 'Item de menu removido com sucesso',
      required: 'O item de menu é obrigatório',
      requiredName: 'O nome do item de menu é obrigatório',
      noParentMenu: 'Sem pai',
    },
    partners: {
      newPartner: "Novo parceiro",
      editPartner: "Editar parceiro {name}",
      duplicatePartner: "Duplicar parceiro {name}",
      insertPartner: 'Sim, adicione o parceiro {name}',
      removePartner: 'Sim, remova o parceiro {name}',
      successInsert: 'Parceiro inserido com sucesso',
      successUpdate: 'Parceiro actualizado com sucesso',
      successDuplicate: 'Parceiro duplicado com sucesso',
      successRemoved: 'Parceiro removido com sucesso',
    },
    params: {
      newParam: "Novo parámetro",
      editParam: "Editar parámetro {name}",
      duplicateParam: "Duplicar parámetro {name}",
      insertParam: 'Sim, adicione o parámetro {name}',
      removeParam: 'Sim, remova o parámetro {name}',
      successInsert: 'Parámetro inserido com sucesso',
      successUpdate: 'Parámetro actualizado com sucesso',
      successDuplicate: 'Parámetro duplicado com sucesso',
      successRemoved: 'Parámetro removido com sucesso',
    },
    payments: {
      wannaChangeRevenueShare: 'Tens certeza que queres alterar o valor da comissão?',
      daysToExpire: 'Dias para expirar',
      methodList: {
        multibanco: {
          title: 'Multibanco',
        },
        payshop: {
          title: 'Payshop',
        },
        mbWay: {
          title: 'MBWay',
        },
        creditCard: {
          title: 'Cartão de crédito',
        },
      },
      newPayment: "Nova forma de pagamento",
      path: "Pasta",
      methods: "Métodos",
      editPayment: "Editar forma de pagamento {name}",
      duplicatePayment: "Duplicar forma de pagamento {name}",
      insertPayment: 'Sim, adicione a forma de pagamento {name}',
      removePayment: 'Sim, remova a forma de pagamento {name}',
      successInsert: 'Forma de Pagamento inserida com sucesso',
      successUpdate: 'Forma de Pagamento actualizada com sucesso',
      successDuplicate: 'Forma de Pagamento duplicada com sucesso',
      successRemoved: 'Forma de Pagamento removida com sucesso',
    },
    installations: {
      label: 'Instalações',
      countryCode: 'Código',
      countryDescription: 'Descrição',
      languageCode: 'Cód Idioma',
      languageDescription: 'Idioma',
      appUrl: 'URL do frontoffice',
      officegest: {
        title: 'Officegest',
        officegestUrl: 'Officegest URL',
        officegestUser: 'Officegest User',
        officegestPassword: 'Officegest Pass',
        defaultArticleCode: 'Código do Artigo Padrão',
        documentSeries: 'Série do Documento',
        documentType: 'Tipo do Documento',
        documentStatus: {
          title: 'Status do Documento',
          drafts: 'Rascunhos',
          documents: 'Formalizado'
        },
        defaultGenericArticleCode: 'Código do Artigo Genérico Padrão',
      },
      pbx: 'PBX',
      newInstallation: "Nova instalação",
      editInstallation: "Editar instalação {country_description}",
      duplicateInstallation: "Duplicar instalação {country_description}",
      insertInstallation: 'Sim, adicione a instalação {country_description}',
      removeInstallation: 'Sim, remova a instalação {country_description}',
      successInsert: 'Instalação inserida com sucesso',
      successUpdate: 'Instalação actualizada com sucesso',
      successDuplicate: 'Instalação duplicada com sucesso',
      successRemoved: 'Instalação removida com sucesso',
    },
    socialLinks: {
      newSocialLink: "Novo link social",
      editSocialLink: "Editar link social {description}",
      duplicateSocialLink: "Duplicar link social {description}",
      insertSocialLink: 'Sim, adicione o link social {description}',
      removeSocialLink: 'Sim, remova o link social {description}',
      successInsert: 'Link social inserido com sucesso',
      successUpdate: 'Link social actualizado com sucesso',
      successDuplicate: 'Link social duplicado com sucesso',
      successRemoved: 'Link social removido com sucesso',
    }
  },
};

export default messages;