import {useAuth} from "@/pinia_stores/auth.js";

export default {
  install: (app, options) => {
    app.config.globalProperties.can = function (value) {
      const store = useAuth();
			if(!store.currentUser?.roles?.permissions) {
        return;
      }
      if (store.currentUser.roles.permissions === 0) {
        return false
      }
      let permissions = store.currentUser.roles.permissions
      let _return = false
      if (!Array.isArray(permissions)) {
        return false
      }
      if (value.includes('|')) {
        value.split('|').forEach(function (item) {
          if (permissions.includes(item.trim())) {
            _return = true
          }
        })
      } else if (value.includes('&')) {
        _return = true
        value.split('&').forEach(function (item) {
          if (!permissions.includes(item.trim())) {
            _return = false
          }
        })
      } else {
        _return = permissions.includes(value.trim())
      }
      return _return
    }
    app.config.globalProperties.is = function (value) {

      if(!store?.state?.AuthModule?.user?.roles?.permissions) {
        return;
      }
      if (store.currentUser.roles.permissions === 0) {
        return false
      }
      let roles = store.currentUser.roles.permissions
      let _return = false
      if (!Array.isArray(roles)) {
        return false
      }
      if (value.includes('|')) {
        value.split('|').forEach(function (item) {
          if (roles.includes(item.trim())) {
            _return = true
          }
        })
      } else if (value.includes('&')) {
        _return = true
        value.split('&').forEach(function (item) {
          if (!roles.includes(item.trim())) {
            _return = false
          }
        })
      } else {
        _return = roles.includes(value.trim())
      }
      return _return
    }
  }
}