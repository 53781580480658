import ApiService from "@/core/services/ApiService";
import {StatusesActions, StatusesMutations} from "@/store/enums/StatusesEnums";

const StatusesModule = {
  state: {
    statuses: [],
    errors: [],
  },
  mutations: {
    [StatusesMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [StatusesMutations.LIST_STATUSES](state, statuses) {
      state.statuses = statuses;
    },
    [StatusesMutations.NEW_STATUS](state, status) {
      state.status = status;
    },
    [StatusesMutations.DELETE_STATUS](state, id) {
      state.statuses = state.statuses.filter((status) => {
        return status.id !== id
      });
    },
  },
  actions: {
    async [StatusesActions.LOAD_STATUS]({ commit }, payload={}) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_STATUSES, payload)
        .then(({data}) => {
          commit(StatusesMutations.LIST_STATUSES, data);
        })
        .catch(({response}) => {
          commit(StatusesMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [StatusesActions.LOAD_ALL_STATUSES]({ commit }, payload={all: 1, section: 'payment'}) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_STATUSES, payload)
        .then(({data}) => {
          commit(StatusesMutations.LIST_STATUSES, data);
        })
        .catch(({response}) => {
          commit(StatusesMutations.SET_ERRORS, response.data.errors);
        });
    },
    [StatusesActions.INSERT_STATUS](context, status) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_STATUSES}`, status);
    },
    [StatusesActions.SAVE_STATUS]({dispatch}, status) {
      if (status.id > 0) {
        if (status.action === 'update') {
          return dispatch(StatusesActions.UPDATE_STATUS, status);
        }
        return dispatch(StatusesActions.INSERT_STATUS, status);
      }
      return dispatch(StatusesActions.INSERT_STATUS, status);
    },
    async [StatusesActions.UPDATE_STATUS]({commit}, status) {
      ApiService.setHeader();
      //await ApiService.csrf();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_STATUSES}/${status.id}`, status);
    },
    [StatusesActions.REMOVE_STATUS]({dispatch}, status) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_STATUSES}/${status.id}`).then(() => dispatch(StatusesActions.LOAD_STATUSES));
    },
    [StatusesActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default StatusesModule