import ApiService from "@/core/services/ApiService";
import {LocalizationsActions, LocalizationsMutations} from "@/store/enums/LocalizationsEnums";

const LocalizationsModule = {
  state: {
    localizations: [],
    localization: {},
    errors: [],
  },
  mutations: {
    [LocalizationsMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [LocalizationsMutations.LIST_LOCALIZATIONS](state, localizations) {
      state.localizations = localizations;
    },
    [LocalizationsMutations.SHOW_LOCALIZATION](state, localization) {
      state.localization = localization;
    },
    [LocalizationsMutations.NEW_LOCALIZATION](state, localization) {
      state.localization = localization;
    },
    [LocalizationsMutations.DELETE_LOCALIZATION](state, id) {
      state.localizations = state.localizations.filter((localization) => {
        return localization.id !== id
      });
    },
  },
  actions: {
    async [LocalizationsActions.LOAD_LOCALIZATION]({ commit }, localizationId={}) {
      ApiService.setHeader();
      await ApiService.get(`${process.env.VUE_APP_API_ENDPOINT_LOCALIZATIONS}/${localizationId}`)
        .then(({data}) => {
          commit(LocalizationsMutations.SHOW_LOCALIZATION, data);
        })
        .catch(({response}) => {
          commit(LocalizationsMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [LocalizationsActions.LOAD_ALL_LOCALIZATIONS]({ commit }, payload={}) {
      ApiService.setHeader();
      return await ApiService.get(process.env.VUE_APP_API_ENDPOINT_LOCALIZATIONS, payload)
        .then(({data}) => {
          commit(LocalizationsMutations.LIST_LOCALIZATIONS, data);
          return data;
        })
        .catch(({response}) => {
          commit(LocalizationsMutations.SET_ERRORS, response.data.errors);
        });
    },
    [LocalizationsActions.INSERT_LOCALIZATION](context, localization) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_LOCALIZATIONS}`, localization);
    },
    [LocalizationsActions.SAVE_LOCALIZATION]({dispatch}, localization) {
      if (localization.id > 0) {
        if (localization.action === 'update') {
          return dispatch(LocalizationsActions.UPDATE_LOCALIZATION, localization);
        }
        return dispatch(LocalizationsActions.INSERT_LOCALIZATION, localization);
      }
      return dispatch(LocalizationsActions.INSERT_LOCALIZATION, localization);
    },
    async [LocalizationsActions.UPDATE_LOCALIZATION]({commit}, localization) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_LOCALIZATIONS}/${localization.id}`, localization);
    },
    [LocalizationsActions.REMOVE_LOCALIZATION]({dispatch}, localization) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_LOCALIZATIONS}/${localization.id}`).then(() => dispatch(LocalizationsActions.LOAD_ALL_LOCALIZATIONS));
    },
    [LocalizationsActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default LocalizationsModule