const ParamsActions = Object.freeze({
  // action types
  INSERT_PARAM: "insertNewParam",
  UPDATE_PARAM: "updateParam",
  SAVE_PARAM: "saveParam",
  REMOVE_PARAM: "removeParam",
  LOAD_PARAMS: "loadParams",
  VIEW_PARAM: "showParam",
  NOTIFY_ERRORS: "notifyErrors",
});
const ParamsMutations = Object.freeze({
  // action types
  NEW_PARAM: "newParam",
  EDIT_PARAM: "editParam",
  DELETE_PARAM: "deleteParam",
  SHOW_PARAM: "showParam",
  LIST_PARAMS: "listParams",
  SET_ERRORS: "errors",
});

export { ParamsActions, ParamsMutations };
