import {createRouter, createWebHistory} from 'vue-router';
import {useAuth} from "@/pinia_stores/auth.js";
import NProgress from "nprogress";
import _ from 'lodash';

const routes = [
  {
    path: '/',
    redirect: "/dashboard",
    component: () => import(/* webpackChunkName: "layout" */ "@/layout/Layout"),
    meta: {auth: true},
    can: [],
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          can: [],
          description: 'Dashboard',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard"),
      },
      {
        path: "/users",
        name: 'users.index',
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Users/Index"),
        children: [
          {
            path: '/users/list/:type?',
            name: 'users.list',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Users/List"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'List users',
              auth: true,
            },
          },
          {
            path: '/users/create',
            name: 'users.create',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Users/Create"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'New User',
              auth: true
            },
          },
          {
            path: '/users/edit/:id',
            name: 'users.edit',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Users/Edit"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'Edit User',
              auth: true
            },
          },
          {
            path: '/users/duplicate/:id',
            name: 'users.duplicate',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Users/Duplicate"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'Duplicate User',
              auth: true
            },
          },
          {
            path: "/users/specialties",
            name: 'users.specialties',
            component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Specialties/Index"),
            children: [
              {
                path: '/users/specialties/list',
                name: 'specialties.list',
                component: () => import(/* webpackChunkName: "users" */ "@/views/Specialties/List"),
                meta: {
                  can: ['Development', 'Super Admin', 'Administrator'],
                  description: 'List specialties',
                  auth: true
                },
              },
              {
                path: '/users/specialties/create',
                name: 'specialties.create',
                component: () => import(/* webpackChunkName: "users" */ "@/views/Specialties/Create"),
                meta: {
                  can: ['Development', 'Super Admin', 'Administrator'],
                  description: 'New Specialty',
                  auth: true
                },
              },
              {
                path: '/users/specialties/edit/:name',
                name: 'specialties.edit',
                component: () => import(/* webpackChunkName: "users" */ "@/views/Specialties/Edit"),
                meta: {
                  can: ['Development', 'Super Admin', 'Administrator'],
                  description: 'Edit Specialty',
                  auth: true
                },
              },
            ]
          },
          {
            path: "/users/localizations",
            name: 'users.localizations',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Localizations/Index"),
            children: [
              {
                path: '/users/localizations/list',
                name: 'localizations.list',
                component: () => import(/* webpackChunkName: "users" */ "@/views/Localizations/List"),
                meta: {
                  can: ['Development', 'Super Admin', 'Administrator'],
                  description: 'List locations',
                  auth: true,
                },
              },
              {
                path: '/users/localizations/create',
                name: 'localizations.create',
                component: () => import(/* webpackChunkName: "users" */ "@/views/Localizations/Create"),
                meta: {
                  can: ['Development', 'Super Admin', 'Administrator'],
                  description: 'New Location',
                  auth: true
                },
              },
              {
                path: '/users/localizations/edit/:id',
                name: 'localizations.edit',
                component: () => import(/* webpackChunkName: "users" */ "@/views/Localizations/Edit"),
                meta: {
                  can: ['Development', 'Super Admin', 'Administrator'],
                  description: 'Edit Location',
                  auth: true
                },
              },
            ]
          },
          {
            path: "/users/testimonials",
            name: 'users.testimonials',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Users/Index"),
            children: [
              {
                path: '/users/testimonials/list',
                name: 'testimonials.list',
                component: () => import(/* webpackChunkName: "users" */ "@/views/Users/Testimonials.vue"),
                meta: {
                  can: ['Development', 'Super Admin', 'Administrator'],
                  description: 'List testimonials',
                  auth: true
                },
              },
            ]
          },
        ]
      },
      {
        path: "/partners",
        name: "partners",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Partners',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Partners/List"),
      },
      {
        path: "/params",
        name: "params",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Params',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "users" */ "@/views/Localizations/Index"),
        children: [
          {
            path: '/params/list',
            name: 'params.list',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Params/List"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'List params',
              auth: true
            },
          },
          {
            path: '/params/create',
            name: 'params.create',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Params/Create"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'New Param',
              auth: true
            },
          },
          {
            path: '/params/edit/:id',
            name: 'params.edit',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Params/Edit"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'Edit Param',
              auth: true
            },
          },
        ]
      },
      {
        path: "/payment-modes",
        name: "payment-modes",
        meta: {
          can: ['Development', 'Super Admin'],
          description: 'Payment modes',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Payments/List"),
      },
      {
        path: "/notifications",
        name: "notifications",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Notifications',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "users" */ "@/views/Localizations/Index"),
        children: [
          {
            path: '/notifications/list',
            name: 'notifications.list',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Notifications/List"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'List notifications',
              auth: true
            },
          },
          {
            path: '/notifications/create',
            name: 'notifications.create',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Notifications/Create"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'New Notification',
              auth: true
            },
          },
          {
            path: '/notifications/edit/:slug',
            name: 'notifications.edit',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Notifications/Edit"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'Edit Notification',
              auth: true
            },
          },
        ]
      },
      {
        path: "/social",
        name: "social",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Social Links',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/SocialLinks/List"),
      },
      {
        path: "/installations",
        name: "installations",
        meta: {
          can: ['Development'],
          description: 'Installations',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Installations/List"),
      },
      {
        path: "/vouchers",
        name: "vouchers",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Vouchers',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Installations/List"),
      },
      {
        path: "/articles",
        name: "articles",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Articles',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "users" */ "@/views/Articles/Index"),
        children: [
          {
            path: '/articles/list',
            name: 'articles.list',
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'Articles',
              isMenu: true,
              auth: true
            },
            component: () => import(/* webpackChunkName: "articles" */ "@/views/Articles/List"),
          },
          {
            path: '/articles/create',
            name: 'articles.create',
            component: () => import(/* webpackChunkName: "articles" */ "@/views/Articles/Create"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'New Article',
              auth: true
            },
          },
          {
            path: '/articles/edit/:slug',
            name: 'articles.edit',
            component: () => import(/* webpackChunkName: "articles" */ "@/views/Articles/Edit"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'Edit Article',
              auth: true,
              hash: Math.random()
            },
          },
          {
            path: '/articles/duplicate/:id',
            name: 'articles.duplicate',
            component: () => import(/* webpackChunkName: "articles" */ "@/views/Articles/Duplicate"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'Duplicate Article',
              auth: true
            },
          },
        ]
      },
      {
        path: "/pages",
        name: "pages",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Pages',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/List"),
      },
      {
        path: '/pages/create',
        name: 'pages.create',
        component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/Create"),
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'New Page',
          auth: true
        },
      },
      {
        path: '/pages/edit/:slug',
        name: 'pages.edit',
        component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/Edit"),
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Edit Page',
          auth: true
        },
      },
      {
        path: '/pages/duplicate/:id',
        name: 'pages.duplicate',
        component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/Duplicate"),
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Duplicate Page',
          auth: true
        },
      },
      {
        path: "/menus",
        name: "menus",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Menus',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "menus" */ "@/views/Menus/List"),
      },
      {
        path: '/menus/create',
        name: 'menus.create',
        component: () => import(/* webpackChunkName: "menus" */ "@/views/Menus/Create"),
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'New Menu',
          auth: true
        },
      },
      {
        path: '/menus/edit/:slug',
        name: 'menus.edit',
        component: () => import(/* webpackChunkName: "menus" */ "@/views/Menus/Edit"),
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Edit Menu',
          auth: true
        },
      },
      {
        path: '/menus/duplicate/:id',
        name: 'menus.duplicate',
        component: () => import(/* webpackChunkName: "menus" */ "@/views/Menus/Duplicate"),
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Duplicate Menu',
          auth: true
        },
      },
      {
        path: "/vouchers",
        name: "vouchers",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Vouchers',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "vouchers" */ "@/views/Vouchers/List"),
      },
      {
        path: "/orders",
        name: "orders",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Orders',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "vouchers" */ "@/views/Orders/List"),
      },
      {
        path: "/orders/:id",
        name: "orders.show",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Show Order',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "vouchers" */ "@/views/Orders/List"),
      },
      {
        path: "/appointments",
        name: "appointments",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Appointments',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "vouchers" */ "@/views/Appointments/List"),
      },
      {
        path: "/appointments/:id",
        name: "appointments.show",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Show Appointment',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "vouchers" */ "@/views/Appointments/List"),
      },
      {
        path: "/translations",
        name: "translations",
        meta: {
          can: ['Development', 'Super Admin', 'Administrator'],
          description: 'Translations',
          isMenu: true,
          auth: true
        },
        component: () => import(/* webpackChunkName: "users" */ "@/views/Localizations/Index"),
        children: [
          {
            path: '/translations/list',
            name: 'translations.list',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Translations/List"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'List translations',
              auth: true
            },
          },
          {
            path: '/translations/create',
            name: 'translations.create',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Translations/Create"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'New Translation',
              auth: true
            },
          },
          {
            path: '/translations/edit/:key',
            name: 'translations.edit',
            component: () => import(/* webpackChunkName: "users" */ "@/views/Translations/Edit"),
            meta: {
              can: ['Development', 'Super Admin', 'Administrator'],
              description: 'Edit Translation',
              auth: true
            },
          },
        ]
      },
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/Auth.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/SignIn')
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/PasswordReset')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return {top: 0};
  }
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  if(to?.meta?.auth === true) {
    useAuth().verifyAuth().then((data) => {
      if (data === false) {
        next({name: 'login'});
      } else {
        if((!_.isEmpty(to.meta.can) && !useAuth().can(to.meta.can))) {
          next({name: 'dashboard'})
        }
        next();
      }
    })
  } else {
    next();
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
router.afterEach(() => {
  NProgress.done();
  NProgress.remove();
});
export default router
