import {createApp, markRaw} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ApiService from "@/core/services/ApiService";
import i18n from "@/core/plugins/i18n";
import {initVeeValidate} from "@/core/plugins/vee-validate";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import ElementPlus from "element-plus";
import "bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/multiselect/themes/default.css';
import 'element-plus/dist/index.css';
import LaravelPermissionToVueJS from '@/core/plugins/laravel-permission.to-vuejs'
import {Money3Directive} from "v-money3";
import LaravelVuePagination from "laravel-vue-pagination";
import {plugin, defaultConfig} from '@formkit/vue';
import {createPinia} from 'pinia'
import piniaPersist from 'pinia-plugin-persist'

const pinia = createPinia()
pinia.use(piniaPersist);
pinia.use(({store}) => {
  store.$router = markRaw(router);
});
const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus);
app.use(plugin, defaultConfig);
ApiService.init(app);
initVeeValidate();
initInlineSvg(app);
app.use(pinia);
app.use(i18n);
app.use(LaravelPermissionToVueJS)
app.component('pagination', LaravelVuePagination);

app.directive('money3', Money3Directive)

app.mount('#app')