const UsersActions = Object.freeze({
  // action types
  INSERT_USER: "insertNewUser",
  UPDATE_USER: "updateUser",
  UPDATE_CUSTOMER: "updateCustomer",
  UPDATE_STATUS: "updateUserStatus",
  UPDATE_PSYCHOLOGIST_ACTIVATION: "updateUserPsychologistActivation",
  UPDATE_PSYCHOLOGIST_DEACTIVATION: "updateUserPsychologistDeactivation",
  SAVE_USER: "saveUser",
  REMOVE_USER: "removeUser",
  LOAD_USERS: "loadUsers",
  LOAD_SPECIALISTS: "loadSpecialists",
  LOAD_USER: "loadUser",
  VIEW_USER: "showUser",
  NOTIFY_ERRORS: "notifyErrors",
});
const UsersMutations = Object.freeze({
  // action types
  NEW_USER: "newUser",
  EDIT_USER: "editUser",
  DELETE_USER: "deleteUser",
  SHOW_USER: "showUser",
  LIST_USERS: "listUsers",
  LIST_SPECIALISTS: "listSpecialists",
  EDIT_PSYCHOLOGIST_ACTIVATION: "editUserPsychologistActivation",
  SET_ERRORS: "errors",
});

export { UsersActions, UsersMutations };
