import ApiService from "@/core/services/ApiService";
import {PaymentMethodsActions, PaymentMethodsMutations} from "@/store/enums/PaymentMethodsEnums";
import {InstallationsActions, InstallationsMutations} from "@/store/enums/InstallationsEnums";

const PaymentMethodsModule = {
  state: {
    paymentMethods: [],
    errors: [],
  },
  mutations: {
    [PaymentMethodsMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [PaymentMethodsMutations.LIST_PAYMENT_METHODS](state, paymentMethods) {
      state.paymentMethods = paymentMethods;
    },
    [PaymentMethodsMutations.NEW_PAYMENT_METHOD](state, paymentMethod) {
      state.paymentMethod = paymentMethod;
    },
    [PaymentMethodsMutations.DELETE_PAYMENT_METHOD](state, id) {
      state.paymentMethods = state.paymentMethods.filter((paymentMethod) => {
        return paymentMethod.id !== id
      });
    },
  },
  actions: {
    async [PaymentMethodsActions.LOAD_ALL_PAYMENT_METHODS]({ commit }) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_PAYMENT_METHODS, '?all=1')
        .then(({data}) => {
          commit(PaymentMethodsMutations.LIST_PAYMENT_METHODS, data);
        })
        .catch(({response}) => {
          commit(PaymentMethodsMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [PaymentMethodsActions.LOAD_PAYMENT_METHODS]({commit}, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_PAYMENT_METHODS, filter)
        .then(({data}) => {
          commit(PaymentMethodsMutations.LIST_PAYMENT_METHODS, data);
        })
        .catch(({response}) => {
          commit(PaymentMethodsMutations.SET_ERRORS, response.data.errors);
        });
    },
    [PaymentMethodsActions.INSERT_PAYMENT_METHOD](context, paymentMethod) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_PAYMENT_METHODS}`, paymentMethod);
    },
    [PaymentMethodsActions.SAVE_PAYMENT_METHOD]({dispatch}, paymentMethod) {
      if (paymentMethod.id > 0) {
        if (paymentMethod.action === 'update') {
          return dispatch(PaymentMethodsActions.UPDATE_PAYMENT_METHOD, paymentMethod);
        }
        return dispatch(PaymentMethodsActions.INSERT_PAYMENT_METHOD, paymentMethod);
      }
      return dispatch(PaymentMethodsActions.INSERT_PAYMENT_METHOD, paymentMethod);
    },
    async [PaymentMethodsActions.UPDATE_PAYMENT_METHOD]({commit}, paymentMethod) {
      ApiService.setHeader();
      //await ApiService.csrf();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_PAYMENT_METHODS}/${paymentMethod.id}`, paymentMethod);
    },
    [PaymentMethodsActions.REMOVE_PAYMENT_METHOD]({dispatch}, paymentMethod) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_PAYMENT_METHODS}/${paymentMethod.id}`).then(() => dispatch(PaymentMethodsActions.LOAD_PAYMENT_METHODS));
    },
    [PaymentMethodsActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default PaymentMethodsModule