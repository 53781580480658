const MenusActions = Object.freeze({
  // action types
  INSERT_MENU: "insertNewMenu",
  UPDATE_MENU: "updateMenu",
  UPDATE_STATUS: "updateMenuStatus",
  SAVE_MENU: "saveMenu",
  REMOVE_MENU: "removeMenu",
  LOAD_MENUS: "loadMenus",
  LOAD_PARENT_MENUS: "loadParentMenus",
  LOAD_MENU: "loadMenu",
  VIEW_MENU: "showMenu",
  REORDER_MENU: "reorderMenu",
  FILL_ORDER_MENU: "fillOrderMenu",
  NOTIFY_ERRORS: "notifyErrors",
});
const MenusMutations = Object.freeze({
  // action types
  NEW_MENU: "newMenu",
  EDIT_MENU: "editMenu",
  DELETE_MENU: "deleteMenu",
  SHOW_MENU: "showMenu",
  LIST_MENUS: "listMenus",
  ORDER_MENUS: "orderMenus",
  LIST_PARENT_MENUS: "listParentMenus",
  SET_ERRORS: "errors",
});

export { MenusActions, MenusMutations };
