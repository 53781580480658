const InstallationsActions = Object.freeze({
  // action types
  INSERT_INSTALLATION: "insertNewInstallation",
  UPDATE_INSTALLATION: "updateInstallation",
  REMOVE_INSTALLATION: "removeInstallation",
  SAVE_INSTALLATION: "saveInstallation",
  LOAD_INSTALLATION: "loadInstallation",
  LOAD_ALL_INSTALLATION: "loadAllInstallation",
  VIEW_INSTALLATION: "showInstallation",
});
const InstallationsMutations = Object.freeze({
  // action types
  NEW_INSTALLATION: "newInstallation",
  EDIT_INSTALLATION: "editInstallation",
  DELETE_INSTALLATION: "deleteInstallation",
  SHOW_INSTALLATION: "showInstallation",
  LIST_INSTALLATION: "listInstallation",
  SET_ERRORS: "errors",
});

export { InstallationsActions, InstallationsMutations };
