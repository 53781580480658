const SchedulesActions = Object.freeze({
  // action types
  INSERT_SCHEDULE: "insertNewSchedule",
  UPDATE_SCHEDULE: "updateSchedule",
  UPDATE_STATUS: "updateScheduleStatus",
  SAVE_SCHEDULE: "saveSchedule",
  REMOVE_SCHEDULE: "removeSchedule",
  LOAD_SCHEDULES: "loadSchedules",
  LOAD_SCHEDULE: "loadSchedule",
  VIEW_SCHEDULE: "showSchedule",
  PAY: "paySchedule",
  NOTIFY_ERRORS: "notifyErrors",
});
const SchedulesMutations = Object.freeze({
  // action types
  NEW_SCHEDULE: "newSchedule",
  EDIT_SCHEDULE: "editSchedule",
  DELETE_SCHEDULE: "deleteSchedule",
  SHOW_SCHEDULE: "showSchedule",
  LIST_SCHEDULES: "listSchedules",
  SET_ERRORS: "errors",
});

export { SchedulesActions, SchedulesMutations };
