import axios from "axios";
import VueAxios from "vue-axios";
import Cookies from 'js-cookie';
import {useAuth} from "@/pinia_stores/auth.js";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  static vueInstance;

  /**
   * @description initialize vue axios
   */
  static init(app) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    ApiService.vueInstance.axios.defaults.withCredentials = true;
    ApiService.vueInstance.axios.defaults.crossDomain = true;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] = "application/json";
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] = "application/json";
    ApiService.vueInstance.axios.defaults.headers.common["X-XSRF-TOKEN"] = Cookies.get('XSRF-TOKEN');
    ApiService.vueInstance.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    ApiService.vueInstance.axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'true';
  }

  /**
   * @description set the default HTTP request headers
   */
  static setHeader(customHeaders = null) {
    if (customHeaders !== null) {
      customHeaders.map((header) => ApiService.vueInstance.axios.defaults.headers.common[header.header] = header.value);
    }
    ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${useAuth().accessToken}`;
  }

  static csrf() {
    //return ApiService.vueInstance.axios.get(process.env.VUE_APP_API_SANCTUM_COOKIE, { withCredentials: true });
  }

  /**
   * @description send the GET HTTP request
   * @param resource
   * @param params
   * @returns Promise
   */
  static query(
    resource,
    params
  ) {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource
   * @param params
   * @returns Promise
   */
  static get(
    resource,
    params
  ) {
    return ApiService.vueInstance.axios.get(`${resource}`, {
      params,
      headers: {
        withCredentials: true
      }
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource
   * @param params
   * @returns Promise
   */
  static post(
    resource,
    params
  ) {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns Promise
   */
  static update(
    resource,
    slug,
    params
  ) {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns Promise
   */
  static put(
    resource,
    params
  ) {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns Promise
   */
  static patch(
    resource,
    params
  ) {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource
   * @returns Promise
   */
  static delete(resource) {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
