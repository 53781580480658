const RolesActions = Object.freeze({
  // action types
  INSERT_ROLE: "insertNewRole",
  UPDATE_ROLE: "updateRole",
  REMOVE_ROLE: "removeRole",
  LOAD_ROLE: "loadRole",
  LOAD_ALL_ROLES: "loadAllRoles",
  VIEW_ROLE: "showRole",
});
const RolesMutations = Object.freeze({
  // action types
  NEW_ROLE: "newRole",
  EDIT_ROLE: "editRole",
  DELETE_ROLE: "deleteRole",
  SHOW_ROLE: "showRole",
  LIST_ROLES: "listRole",
  SET_ERRORS: "errors",
});

export { RolesActions, RolesMutations };
