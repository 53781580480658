import ApiService from "@/core/services/ApiService";
import {ArticlesActions, ArticlesMutations} from "@/store/enums/ArticlesEnums";

const ArticlesModule = {
  state: {
    articles: [],
    article: {},
    errors: [],
  },
  mutations: {
    [ArticlesMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [ArticlesMutations.LIST_ARTICLES](state, articles) {
      state.articles = articles;
    },
    [ArticlesMutations.SHOW_ARTICLE](state, article) {
      state.article = article;
    },
    [ArticlesMutations.NEW_ARTICLE](state, article) {
      state.article = article;
    },
    [ArticlesMutations.DELETE_ARTICLE](state, id) {
      state.articles = state.articles.filter((article) => {
        return article.id !== id
      });
    },
  },
  actions: {
    async [ArticlesActions.LOAD_ARTICLE]({commit}, id, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_ARTICLES + `/${id}`, {})
        .then(({data}) => {
          commit(ArticlesMutations.SHOW_ARTICLE, data);
        })
        .catch(({response}) => {
          commit(ArticlesMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [ArticlesActions.LOAD_ARTICLES]({commit}, filter= null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_ARTICLES, filter)
        .then(({data}) => {
          commit(ArticlesMutations.LIST_ARTICLES, data);
        })
        .catch(({response}) => {
          commit(ArticlesMutations.SET_ERRORS, response.data.errors);
        });
    },
    [ArticlesActions.INSERT_ARTICLE](context, article) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_ARTICLES}`, article);
    },
    [ArticlesActions.UPDATE_ARTICLE](context, article) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_ARTICLES}/${article.get('name')}`, article);
    },
    async [ArticlesActions.UPDATE_STATUS]({commit}, article) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_ARTICLES}/${article.id}/status`, article);
    },
    [ArticlesActions.REMOVE_ARTICLE]({dispatch}, article) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_ARTICLES}/${article.name}`).then(() => dispatch(ArticlesActions.LOAD_ARTICLES));
    },
    [ArticlesActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default ArticlesModule