import ApiService from "@/core/services/ApiService";
import {SocialLinksActions, SocialLinksMutations} from "@/store/enums/SocialLinksEnums";

const SocialLinksModule = {
  state: {
    socialLinks: [],
    errors: [],
  },
  mutations: {
    [SocialLinksMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [SocialLinksMutations.LIST_SOCIAL_LINKS](state, socialLinks) {
      state.socialLinks = socialLinks;
    },
    [SocialLinksMutations.NEW_SOCIAL_LINK](state, socialLink) {
      state.socialLink = socialLink;
    },
    [SocialLinksMutations.DELETE_SOCIAL_LINK](state, id) {
      state.socialLinks = state.socialLinks.filter((socialLink) => {
        return socialLink.id !== id
      });
    },
  },
  actions: {
    async [SocialLinksActions.LOAD_SOCIAL_LINKS]({commit}, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_SOCIAL_LINKS)
        .then(({data}) => {
          commit(SocialLinksMutations.LIST_SOCIAL_LINKS, data);
        })
        .catch(({response}) => {
          commit(SocialLinksMutations.SET_ERRORS, response.data.errors);
        });
    },
    [SocialLinksActions.INSERT_SOCIAL_LINK](context, socialLink) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_SOCIAL_LINKS}`, socialLink);
    },
    [SocialLinksActions.SAVE_SOCIAL_LINK]({dispatch}, socialLink) {
      if (socialLink.id > 0) {
        if (socialLink.action === 'update') {
          return dispatch(SocialLinksActions.UPDATE_SOCIAL_LINK, socialLink);
        }
        return dispatch(SocialLinksActions.INSERT_SOCIAL_LINK, socialLink);
      }
      return dispatch(SocialLinksActions.INSERT_SOCIAL_LINK, socialLink);
    },
    async [SocialLinksActions.UPDATE_SOCIAL_LINK]({commit}, socialLink) {
      ApiService.setHeader();
      //await ApiService.csrf();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_SOCIAL_LINKS}/${socialLink.id}`, socialLink);
    },
    [SocialLinksActions.REMOVE_SOCIAL_LINK]({dispatch}, socialLink) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_SOCIAL_LINKS}/${socialLink.id}`).then(() => dispatch(SocialLinksActions.LOAD_SOCIAL_LINKS));
    },
    [SocialLinksActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default SocialLinksModule