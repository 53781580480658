import ApiService from "@/core/services/ApiService";
import {UsersActions, UsersMutations} from "@/store/enums/UsersEnums";

const UsersModule = {
  state: {
    users: [],
    specialists: [],
    user: {},
    errors: [],
  },
  mutations: {
    [UsersMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [UsersMutations.LIST_USERS](state, users) {
      state.users = users;
    },
    [UsersMutations.LIST_SPECIALISTS](state, specialists) {
      state.specialists = specialists;
    },
    [UsersMutations.SHOW_USER](state, user) {
      state.user = user;
    },
    [UsersMutations.NEW_USER](state, user) {
      state.user = user;
    },
    [UsersMutations.DELETE_USER](state, id) {
      state.users = state.users.filter((user) => {
        return user.id !== id
      });
    },
  },
  actions: {
    async [UsersActions.LOAD_USER]({commit}, id, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_USERS + `/${id}`, {})
        .then(({data}) => {
          commit(UsersMutations.SHOW_USER, data);
        })
        .catch((response) => {
          console.log(response)
          commit(UsersMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [UsersActions.LOAD_USERS]({commit}, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_USERS, filter)
        .then(({data}) => {
          commit(UsersMutations.LIST_USERS, data);
        })
        .catch(({response}) => {
          commit(UsersMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [UsersActions.LOAD_SPECIALISTS]({commit}, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_USERS, filter)
          .then(({data}) => {
            commit(UsersMutations.LIST_SPECIALISTS, data);
          })
          .catch(({response}) => {
            commit(UsersMutations.SET_ERRORS, response.data.errors);
          });
    },
    [UsersActions.INSERT_USER](context, user) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_USERS}`, user);
    },
    [UsersActions.UPDATE_USER](context, user) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_USERS}/${user.id}`, user);
    },
    async [UsersActions.UPDATE_STATUS]({commit}, user) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_USERS}/${user.id}/status`, user);
    },
    async [UsersActions.UPDATE_PSYCHOLOGIST_ACTIVATION]({commit}, user) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_USERS}/psychologist/${user.id}/activate`, user);
    },
    async [UsersActions.UPDATE_PSYCHOLOGIST_DEACTIVATION]({commit}, user) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_USERS}/psychologist/${user.id}/deactivate`, user);
    },
    async [UsersActions.UPDATE_CUSTOMER]({commit}, user) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_CUSTOMERS}/${user.id}`, user);
    },
    [UsersActions.REMOVE_USER]({dispatch}, user) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_USERS}/${user.id}`).then(() => dispatch(UsersActions.LOAD_USERS));
    },
    [UsersActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default UsersModule