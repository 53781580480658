import ApiService from "@/core/services/ApiService";
import {SchedulesActions, SchedulesMutations} from "@/store/enums/SchedulesEnums";

const SchedulesModule = {
    state: {
        schedules: [],
        errors: [],
    },
    mutations: {
        [SchedulesMutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },
        [SchedulesMutations.LIST_SCHEDULES](state, schedules) {
            state.schedules = schedules;
        },
        [SchedulesMutations.NEW_SCHEDULE](state, schedule) {
            state.schedule = schedule;
        },
        [SchedulesMutations.DELETE_SCHEDULE](state, id) {
            state.schedules = state.schedules.filter((schedule) => {
                return schedule.id !== id
            });
        },
    },
    actions: {
        async [SchedulesActions.LOAD_SCHEDULES]({commit}, filter = null) {
            ApiService.setHeader();
            return await ApiService.get(process.env.VUE_APP_API_ENDPOINT_SCHEDULES, filter)
                .then(({data}) => {
                    commit(SchedulesMutations.LIST_SCHEDULES, data);
                })
                .catch(({response}) => {
                    commit(SchedulesMutations.SET_ERRORS, response.data.errors);
                });
        },
        [SchedulesActions.INSERT_SCHEDULE](context, schedule) {
            ApiService.setHeader();
            return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_SCHEDULES}`, schedule);
        },
        [SchedulesActions.PAY](context, schedule) {
            ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
            return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_SCHEDULES}/pay`, schedule);
        },
        [SchedulesActions.NOTIFY_ERRORS]({dispatch}, error) {

        },
    },
};
export default SchedulesModule