const VouchersActions = Object.freeze({
  // action types
  INSERT_VOUCHER: "insertNewVoucher",
  UPDATE_VOUCHER: "updateVoucher",
  UPDATE_STATUS: "updateVoucherStatus",
  SAVE_VOUCHER: "saveVoucher",
  REMOVE_VOUCHER: "removeVoucher",
  LOAD_VOUCHERS: "loadVouchers",
  LOAD_VOUCHER: "loadVoucher",
  VIEW_VOUCHER: "showVoucher",
  NOTIFY_ERRORS: "notifyErrors",
});
const VouchersMutations = Object.freeze({
  // action types
  NEW_VOUCHER: "newVoucher",
  EDIT_VOUCHER: "editVoucher",
  DELETE_VOUCHER: "deleteVoucher",
  SHOW_VOUCHER: "showVoucher",
  LIST_VOUCHERS: "listVouchers",
  SET_ERRORS: "errors",
});

export { VouchersActions, VouchersMutations };
