import ApiService from "@/core/services/ApiService.js";
import {ParamsActions, ParamsMutations} from "@/store/enums/ParamsEnums.js";

const ParamsModule = {
  state: {
    params: [],
    errors: [],
  },
  mutations: {
    [ParamsMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [ParamsMutations.LIST_PARAMS](state, params) {
      state.params = params;
    },
    [ParamsMutations.NEW_PARAM](state, param) {
      state.param = param;
    },
    [ParamsMutations.DELETE_PARAM](state, id) {
      state.params = state.params.filter((param) => {
        return param.id !== id
      });
    },
  },
  actions: {
    async [ParamsActions.LOAD_PARAMS]({commit}, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_PARAMS)
        .then(({data}) => {
          commit(ParamsMutations.LIST_PARAMS, data);
        })
        .catch(({response}) => {
          commit(ParamsMutations.SET_ERRORS, response.data.errors);
        });
    },
    [ParamsActions.INSERT_PARAM](context, param) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_PARAMS}`, param);
    },
    [ParamsActions.SAVE_PARAM]({dispatch}, param) {
      if (param.id > 0) {
        if (param.action === 'update') {
          return dispatch(ParamsActions.UPDATE_PARAM, param);
        }
        return dispatch(ParamsActions.INSERT_PARAM, param);
      }
      return dispatch(ParamsActions.INSERT_PARAM, param);
    },
    async [ParamsActions.UPDATE_PARAM]({commit}, param) {
      ApiService.setHeader();
      //await ApiService.csrf();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_PARAMS}/${param.id}`, param);
    },
    [ParamsActions.REMOVE_PARAM]({dispatch}, param) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_PARAMS}/${param.id}`).then(() => dispatch(ParamsActions.LOAD_PARAMS));
    },
    [ParamsActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default ParamsModule