import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import createPersistedState from "vuex-persistedstate";
import Cookies from 'js-cookie';

const AuthModule = {
  namespaced: true,
  state: {
    errors: {},
    err: {},
    user: {},
    credentials: {},
    isAuthenticated: false,
    token: '',
  },
  plugins: [createPersistedState({
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value),
      removeItem: key => Cookies.remove(key)
    }
  })],
  getters: {
    currentUser(state) {
      return state.user;
    },
    credentials(state) {
      return state.credentials;
    },
    isUserAuthenticated(state) {
      return state.isAuthenticated;
    },
    token(state) {
      return state.token;
    },
    getErrors(state) {
      return state.errors;
    }
  },
  mutations: {
    [Mutations.SET_ERROR](state, errors) {
      state.err = {...errors};
    },
    [Mutations.SET_AUTH](state, user) {
      state.isAuthenticated = true;
      state.user = user.data;
      state.errors = {};
      state.token = user.api_token;
      JwtService.saveToken(user.api_token);
    },
    [Mutations.SET_CREDENTIALS](state, credentials) {
      state.credentials = credentials;
    },
    [Mutations.SET_USER](state, user) { //12088307991
      state.user = user;
    },
    [Mutations.SET_PASSWORD](state, password) {
      state.user.password = password;
    },
    [Mutations.PURGE_AUTH](state) {
      state.isAuthenticated = false;
      state.isAuthenticated = false;
      state.user = {};
      state.token = '';
      state.errors = [];
      JwtService.destroyToken();
    }
  },
  actions: {
    async [Actions.LOGIN](credentials, payload) {
      //await ApiService.csrf();
      return await ApiService.post(process.env.VUE_APP_API_ENDPOINT_LOGIN, payload)
        .then(({data}) => {
          if (!data || !data.success || data.success === false) {
            credentials.commit(Mutations.SET_ERROR, [data.message]);
            return;
          }
          credentials.commit(Mutations.SET_ERROR, {});
          credentials.commit(Mutations.SET_AUTH, data);
          credentials.commit(Mutations.SET_CREDENTIALS, payload);
        })
        .catch(({response}) => {
          if(response.status === 401) {
            credentials.commit(Mutations.SET_ERROR, {error: response.data.message});
            return;
          }
          credentials.commit(Mutations.SET_ERROR, response.data.errors);
        });
    },
    async [Actions.LOGOUT]({commit}) {
      /*return await ApiService.csrf().then(() => {

      });*/
      return ApiService.delete(process.env.VUE_APP_API_ENDPOINT_LOGOUT)
        .then(({data}) => {
          if (!data || !data.success || data.success === false) {
            commit(Mutations.SET_ERROR, [data.message]);
            return;
          }
          commit(Mutations.SET_ERROR, {});
          commit(Mutations.PURGE_AUTH);
        })
        .catch(({response}) => {
          commit(Mutations.SET_ERROR, response.data.errors);
        });
    },
    [Actions.REGISTER](credentials) {
      return ApiService.post("register", credentials)
        .then(({data}) => {
          credentials.commit(Mutations.SET_AUTH, data);
        })
        .catch(({response}) => {
          credentials.commit(Mutations.SET_ERROR, response.data.errors);
        });
    },
    [Actions.FORGOT_PASSWORD](payload) {
      return ApiService.post("forgot_password", payload)
        .then(() => {
          payload.commit(Mutations.SET_ERROR, {});
        })
        .catch(({response}) => {
          payload.commit(Mutations.SET_ERROR, response.data.errors);
        });
    },
    async [Actions.VERIFY_AUTH](payload) {
      let token = payload.getters.token;
      if (token) {
        /*await ApiService.csrf().then((response) => {

        });*/
        ApiService.setHeader();
        ApiService.post(process.env.VUE_APP_API_ENDPOINT_CHECK_TOKEN, {})
          .then(({data}) => {
            if(data.success === false) {
              payload.commit(Mutations.PURGE_AUTH);
              return;
            }
            payload.commit(Mutations.SET_AUTH, data);
          })
          .catch(({response}) => {
            payload.commit(Mutations.SET_ERROR, response.data.errors);
            payload.commit(Mutations.PURGE_AUTH);
          });
      } else {
        payload.commit(Mutations.PURGE_AUTH);
        return false;
      }
    },
  },
};
export default AuthModule