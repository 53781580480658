const SocialLinksActions = Object.freeze({
  // action types
  INSERT_SOCIAL_LINK: "insertNewSocialLink",
  UPDATE_SOCIAL_LINK: "updateSocialLink",
  SAVE_SOCIAL_LINK: "saveSocialLink",
  REMOVE_SOCIAL_LINK: "removeSocialLink",
  LOAD_SOCIAL_LINKS: "loadSocialLinks",
  VIEW_SOCIAL_LINK: "showSocialLink",
  NOTIFY_ERRORS: "notifyErrors",
});
const SocialLinksMutations = Object.freeze({
  // action types
  NEW_SOCIAL_LINK: "newSocialLink",
  EDIT_SOCIAL_LINK: "editSocialLink",
  DELETE_SOCIAL_LINK: "deleteSocialLink",
  SHOW_SOCIAL_LINK: "showSocialLink",
  LIST_SOCIAL_LINKS: "listSocialLinks",
  SET_ERRORS: "errors",
});

export { SocialLinksActions, SocialLinksMutations };
