const PaymentMethodsActions = Object.freeze({
  // action types
  INSERT_PAYMENT_METHOD: "insertNewPaymentMethod",
  UPDATE_PAYMENT_METHOD: "updatePaymentMethod",
  SAVE_PAYMENT_METHOD: "savePaymentMethod",
  REMOVE_PAYMENT_METHOD: "removePaymentMethod",
  LOAD_PAYMENT_METHODS: "loadPaymentMethods",
  LOAD_ALL_PAYMENT_METHODS: "loadAllPaymentMethods",
  VIEW_PAYMENT_METHOD: "showPaymentMethod",
  NOTIFY_ERRORS: "notifyErrors",
});
const PaymentMethodsMutations = Object.freeze({
  // action types
  NEW_PAYMENT_METHOD: "newPaymentMethod",
  EDIT_PAYMENT_METHOD: "editPaymentMethod",
  DELETE_PAYMENT_METHOD: "deletePaymentMethod",
  SHOW_PAYMENT_METHOD: "showPaymentMethod",
  LIST_PAYMENT_METHODS: "listPaymentMethods",
  SET_ERRORS: "errors",
});

export { PaymentMethodsActions, PaymentMethodsMutations };
