import ApiService from "@/core/services/ApiService";
import {PaymentsActions, PaymentsMutations} from "@/store/enums/PaymentsEnums";

const PaymentsModule = {
  state: {
    payments: [],
    errors: [],
  },
  mutations: {
    [PaymentsMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [PaymentsMutations.LIST_PAYMENTS](state, payments) {
      state.payments = payments;
    },
    [PaymentsMutations.NEW_PAYMENT](state, payment) {
      state.payment = payment;
    },
    [PaymentsMutations.DELETE_PAYMENT](state, id) {
      state.payments = state.payments.filter((payment) => {
        return payment.id !== id
      });
    },
  },
  actions: {
    async [PaymentsActions.LOAD_PAYMENTS]({commit}, filter= {}) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_PAYMENTS, filter)
        .then(({data}) => {
          commit(PaymentsMutations.LIST_PAYMENTS, data);
        })
        .catch(({response}) => {
          commit(PaymentsMutations.SET_ERRORS, response.data.errors);
        });
    },
    [PaymentsActions.INSERT_PAYMENT](context, payment) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_PAYMENTS}`, payment);
    },
    [PaymentsActions.SAVE_PAYMENT]({dispatch}, payment) {
      if (payment.id > 0) {
        if (payment.action === 'update') {
          return dispatch(PaymentsActions.UPDATE_PAYMENT, payment);
        }
        return dispatch(PaymentsActions.INSERT_PAYMENT, payment);
      }
      return dispatch(PaymentsActions.INSERT_PAYMENT, payment);
    },
    async [PaymentsActions.UPDATE_PAYMENT]({commit}, payment) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_PAYMENTS}/${payment.id}`, payment);
    },
    async [PaymentsActions.UPDATE_STATUS]({commit}, payment) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_PAYMENTS}/${payment.id}/status`, payment);
    },
    [PaymentsActions.REMOVE_PAYMENT]({dispatch}, payment) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_PAYMENTS}/${payment.id}`).then(() => dispatch(PaymentsActions.LOAD_PAYMENTS));
    },
    [PaymentsActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default PaymentsModule