const PartnersActions = Object.freeze({
  // action types
  INSERT_PARTNER: "insertNewPartner",
  UPDATE_PARTNER: "updatePartner",
  SAVE_PARTNER: "savePartner",
  REMOVE_PARTNER: "removePartner",
  LOAD_PARTNERS: "loadPartners",
  VIEW_PARTNER: "showPartner",
  NOTIFY_ERRORS: "notifyErrors",
});
const PartnersMutations = Object.freeze({
  // action types
  NEW_PARTNER: "newPartner",
  EDIT_PARTNER: "editPartner",
  DELETE_PARTNER: "deletePartner",
  SHOW_PARTNER: "showPartner",
  LIST_PARTNERS: "listPartners",
  SET_ERRORS: "errors",
});

export { PartnersActions, PartnersMutations };
