import ApiService from "@/core/services/ApiService";
import {VouchersActions, VouchersMutations} from "@/store/enums/VouchersEnums";

const VouchersModule = {
  state: {
    vouchers: [],
    errors: [],
  },
  mutations: {
    [VouchersMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [VouchersMutations.LIST_VOUCHERS](state, vouchers) {
      state.vouchers = vouchers;
    },
    [VouchersMutations.NEW_VOUCHER](state, voucher) {
      state.voucher = voucher;
    },
    [VouchersMutations.DELETE_VOUCHER](state, id) {
      state.vouchers = state.vouchers.filter((voucher) => {
        return voucher.id !== id
      });
    },
  },
  actions: {
    async [VouchersActions.LOAD_VOUCHERS]({commit}, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_VOUCHERS, filter)
        .then(({data}) => {
          commit(VouchersMutations.LIST_VOUCHERS, data);
        })
        .catch(({response}) => {
          commit(VouchersMutations.SET_ERRORS, response.data.errors);
        });
    },
    [VouchersActions.INSERT_VOUCHER](context, voucher) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_VOUCHERS}`, voucher);
    },
    [VouchersActions.SAVE_VOUCHER]({dispatch}, voucher) {
      if (voucher.id > 0) {
        if (voucher.action === 'update') {
          return dispatch(VouchersActions.UPDATE_VOUCHER, voucher);
        }
        return dispatch(VouchersActions.INSERT_VOUCHER, voucher);
      }
      return dispatch(VouchersActions.INSERT_VOUCHER, voucher);
    },
    async [VouchersActions.UPDATE_VOUCHER]({commit}, voucher) {
      ApiService.setHeader();
      //await ApiService.csrf();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_VOUCHERS}/${voucher.id}`, voucher);
    },
    [VouchersActions.REMOVE_VOUCHER]({dispatch}, voucher) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_VOUCHERS}/${voucher.id}`).then(() => dispatch(VouchersActions.LOAD_VOUCHERS));
    },
    [VouchersActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default VouchersModule