import ApiService from "@/core/services/ApiService";
import {LanguagesActions, LanguagesMutations} from "@/store/enums/LanguagesEnums";

const LanguagesModule = {
  state: {
    languages: [],
    errors: [],
  },
  mutations: {
    [LanguagesMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [LanguagesMutations.LIST_LANGUAGES](state, languages) {
      state.languages = languages;
    },
    [LanguagesMutations.NEW_LANGUAGE](state, language) {
      state.language = language;
    },
    [LanguagesMutations.DELETE_LANGUAGE](state, id) {
      state.languages = state.languages.filter((language) => {
        return language.id !== id
      });
    },
  },
  actions: {
    async [LanguagesActions.LOAD_LANGUAGE]({ commit }, payload={}) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_LANGUAGES, payload)
        .then(({data}) => {
          commit(LanguagesMutations.LIST_LANGUAGES, data);
        })
        .catch(({response}) => {
          commit(LanguagesMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [LanguagesActions.LOAD_ALL_LANGUAGES]({ commit }, payload={}) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_LANGUAGES, payload)
        .then(({data}) => {
          commit(LanguagesMutations.LIST_LANGUAGES, data);
        })
        .catch(({response}) => {
          commit(LanguagesMutations.SET_ERRORS, response.data.errors);
        });
    },
    [LanguagesActions.INSERT_LANGUAGE](context, language) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_LANGUAGES}`, language);
    },
    [LanguagesActions.SAVE_LANGUAGE]({dispatch}, language) {
      if (language.id > 0) {
        if (language.action === 'update') {
          return dispatch(LanguagesActions.UPDATE_LANGUAGE, language);
        }
        return dispatch(LanguagesActions.INSERT_LANGUAGE, language);
      }
      return dispatch(LanguagesActions.INSERT_LANGUAGE, language);
    },
    async [LanguagesActions.UPDATE_LANGUAGE]({commit}, language) {
      ApiService.setHeader();
      //await ApiService.csrf();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_LANGUAGES}/${language.id}`, language);
    },
    [LanguagesActions.REMOVE_LANGUAGE]({dispatch}, language) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_LANGUAGES}/${language.id}`).then(() => dispatch(LanguagesActions.LOAD_LANGUAGES));
    },
    [LanguagesActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default LanguagesModule