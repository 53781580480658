import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import AuthModule from "@/store/modules/AuthModule.js";
import PartnersModule from "@/store/modules/PartnersModule.js";
import ParamsModule from "@/store/modules/ParamsModule.js";
import PaymentsModule from "@/store/modules/PaymentsModule.js";
import PaymentMethodsModule from "@/store/modules/PaymentMethodsModule.js";
import SocialLinksModule from "@/store/modules/SocialLinksModule.js";
import ArticlesModule from "@/store/modules/ArticlesModule.js";
import PagesModule from "@/store/modules/PagesModule.js"
import MenusModule from "@/store/modules/MenusModule.js"
import VouchersModule from "@/store/modules/VouchersModule.js"
import UsersModule from "@/store/modules/UsersModule.js"
import InstallationsModule from "@/store/modules/InstallationsModule.js";
import LanguagesModule from "@/store/modules/LanguagesModule.js";
import StatusesModule from "@/store/modules/StatusesModule.js";
import RolesModule from "@/store/modules/RolesModule.js";
import SchedulesModule from "@/store/modules/SchedulesModule.js";
import SpecialtiesModule from "@/store/modules/SpecialtiesModule.js";
import LocalizationsModule from "@/store/modules/LocalizationsModule.js";

const store = createStore({
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    AuthModule,
    PartnersModule,
    ParamsModule,
    PaymentsModule,
    PaymentMethodsModule,
    PagesModule,
    MenusModule,
    SocialLinksModule,
    ArticlesModule,
    InstallationsModule,
    LanguagesModule,
    StatusesModule,
    RolesModule,
    VouchersModule,
    UsersModule,
    SchedulesModule,
    SpecialtiesModule,
    LocalizationsModule,
  },
});

export default store;
