import ApiService from "@/core/services/ApiService";
import {RolesActions, RolesMutations} from "@/store/enums/RolesEnums";

const RolesModule = {
  state: {
    roles: [],
    errors: [],
  },
  mutations: {
    [RolesMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [RolesMutations.LIST_ROLES](state, roles) {
      state.roles = roles;
    },
    [RolesMutations.NEW_ROLE](state, role) {
      state.role = role;
    },
    [RolesMutations.DELETE_ROLE](state, id) {
      state.roles = state.roles.filter((role) => {
        return role.id !== id
      });
    },
  },
  actions: {
    async [RolesActions.LOAD_ROLE]({ commit }, payload={}) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_ROLES, payload)
        .then(({data}) => {
          commit(RolesMutations.LIST_ROLES, data);
        })
        .catch(({response}) => {
          commit(RolesMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [RolesActions.LOAD_ALL_ROLES]({ commit }, payload={}) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_ROLES, payload)
        .then(({data}) => {
          commit(RolesMutations.LIST_ROLES, data);
        })
        .catch(({response}) => {
          commit(RolesMutations.SET_ERRORS, response.data.errors);
        });
    },
    [RolesActions.INSERT_ROLE](context, role) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_ROLES}`, role);
    },
    [RolesActions.SAVE_ROLE]({dispatch}, role) {
      if (role.id > 0) {
        if (role.action === 'update') {
          return dispatch(RolesActions.UPDATE_ROLE, role);
        }
        return dispatch(RolesActions.INSERT_ROLE, role);
      }
      return dispatch(RolesActions.INSERT_ROLE, role);
    },
    async [RolesActions.UPDATE_ROLE]({commit}, role) {
      ApiService.setHeader();
      //await ApiService.csrf();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_ROLES}/${role.id}`, role);
    },
    [RolesActions.REMOVE_ROLE]({dispatch}, role) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_ROLES}/${role.id}`).then(() => dispatch(RolesActions.LOAD_ROLES));
    },
    [RolesActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default RolesModule