import { createI18n } from "vue-i18n";
import messages from '@/core/lang/messages';

const i18n = createI18n({
    legacy: false,
    locale: "en",
    fallbackLocale: "pt_PT",
    globalInjection: true,
    useScope: 'global',
    messages,
});

export default i18n;
