const PaymentsActions = Object.freeze({
  // action types
  INSERT_PAYMENT: "insertNewPayment",
  UPDATE_PAYMENT: "updatePayment",
  SAVE_PAYMENT: "savePayment",
  REMOVE_PAYMENT: "removePayment",
  LOAD_PAYMENTS: "loadPayments",
  VIEW_PAYMENT: "showPayment",
  UPDATE_STATUS: "updatePaymentStatus",
  NOTIFY_ERRORS: "notifyErrors",
});
const PaymentsMutations = Object.freeze({
  // action types
  NEW_PAYMENT: "newPayment",
  EDIT_PAYMENT: "editPayment",
  DELETE_PAYMENT: "deletePayment",
  SHOW_PAYMENT: "showPayment",
  LIST_PAYMENTS: "listPayments",
  SET_ERRORS: "errors",
});

export { PaymentsActions, PaymentsMutations };
