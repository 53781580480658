const PagesActions = Object.freeze({
  // action types
  INSERT_PAGE: "insertNewPage",
  UPDATE_PAGE: "updatePage",
  UPDATE_STATUS: "updatePageStatus",
  SAVE_PAGE: "savePage",
  REMOVE_PAGE: "removePage",
  LOAD_PAGES: "loadPages",
  LOAD_PAGE: "loadPage",
  VIEW_PAGE: "showPage",
  NOTIFY_ERRORS: "notifyErrors",
});
const PagesMutations = Object.freeze({
  // action types
  NEW_PAGE: "newPage",
  EDIT_PAGE: "editPage",
  DELETE_PAGE: "deletePage",
  SHOW_PAGE: "showPage",
  LIST_PAGES: "listPages",
  SET_ERRORS: "errors",
});

export { PagesActions, PagesMutations };
