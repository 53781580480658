const LanguagesActions = Object.freeze({
  // action types
  INSERT_LANGUAGE: "insertNewLanguage",
  UPDATE_LANGUAGE: "updateLanguage",
  REMOVE_LANGUAGE: "removeLanguage",
  LOAD_LANGUAGE: "loadLanguage",
  LOAD_ALL_LANGUAGES: "loadAllLanguages",
  VIEW_LANGUAGE: "showLanguage",
});
const LanguagesMutations = Object.freeze({
  // action types
  NEW_LANGUAGE: "newLanguage",
  EDIT_LANGUAGE: "editLanguage",
  DELETE_LANGUAGE: "deleteLanguage",
  SHOW_LANGUAGE: "showLanguage",
  LIST_LANGUAGES: "listLanguage",
  SET_ERRORS: "errors",
});

export { LanguagesActions, LanguagesMutations };
