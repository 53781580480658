import ApiService from "@/core/services/ApiService";
import {PagesActions, PagesMutations} from "@/store/enums/PagesEnums";

const PagesModule = {
  state: {
    pages: [],
    page: {},
    errors: [],
  },
  mutations: {
    [PagesMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [PagesMutations.LIST_PAGES](state, pages) {
      state.pages = pages;
    },
    [PagesMutations.SHOW_PAGE](state, page) {
      state.page = page;
    },
    [PagesMutations.NEW_PAGE](state, page) {
      state.page = page;
    },
    [PagesMutations.DELETE_PAGE](state, id) {
      state.pages = state.pages.filter((page) => {
        return page.id !== id
      });
    },
  },
  actions: {
    async [PagesActions.LOAD_PAGE]({commit}, id, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_PAGES + `/${id}`, {})
        .then(({data}) => {
          commit(PagesMutations.SHOW_PAGE, data);
        })
        .catch(({response}) => {
          commit(PagesMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [PagesActions.LOAD_PAGES]({commit}, filter= null) {
      ApiService.setHeader();
      return await ApiService.get(process.env.VUE_APP_API_ENDPOINT_PAGES, filter)
        .then(({data}) => {
          commit(PagesMutations.LIST_PAGES, data);
          return data;
        })
        .catch(({response}) => {
          commit(PagesMutations.SET_ERRORS, response.data.errors);
        });
    },
    [PagesActions.INSERT_PAGE](context, page) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_PAGES}`, page);
    },
    [PagesActions.UPDATE_PAGE](context, page) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_PAGES}/${page.get('name')}`, page);
    },
    async [PagesActions.UPDATE_STATUS]({commit}, page) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_PAGES}/${page.id}/status`, page);
    },
    [PagesActions.REMOVE_PAGE]({dispatch}, page) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_PAGES}/${page.name}`).then(() => dispatch(PagesActions.LOAD_PAGES));
    },
    [PagesActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default PagesModule