import ApiService from "@/core/services/ApiService";
import {SpecialtiesActions, SpecialtiesMutations} from "@/store/enums/SpecialtiesEnums";

const SpecialtiesModule = {
  state: {
    specialties: [],
    specialty: {},
    errors: [],
  },
  mutations: {
    [SpecialtiesMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [SpecialtiesMutations.LIST_SPECIALTIES](state, specialties) {
      state.specialties = specialties;
    },
    [SpecialtiesMutations.SHOW_SPECIALTY](state, specialty) {
      state.specialty = specialty;
    },
    [SpecialtiesMutations.NEW_SPECIALTY](state, specialty) {
      state.specialty = specialty;
    },
    [SpecialtiesMutations.DELETE_SPECIALTY](state, id) {
      state.specialties = state.specialties.filter((specialty) => {
        return specialty.id !== id
      });
    },
  },
  actions: {
    async [SpecialtiesActions.LOAD_SPECIALTY]({commit}, id, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_SPECIALTIES + `/${id}`, {})
        .then(({data}) => {
          commit(SpecialtiesMutations.SHOW_SPECIALTY, data);
        })
        .catch((response) => {
          commit(SpecialtiesMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [SpecialtiesActions.LOAD_SPECIALTIES]({commit}, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_SPECIALTIES, filter)
        .then(({data}) => {
          commit(SpecialtiesMutations.LIST_SPECIALTIES, data);
        })
        .catch(({response}) => {
          commit(SpecialtiesMutations.SET_ERRORS, response.data.errors);
        });
    },
    [SpecialtiesActions.INSERT_SPECIALTY](context, specialty) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_SPECIALTIES}`, specialty);
    },
    [SpecialtiesActions.UPDATE_SPECIALTY](context, specialty) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_SPECIALTIES}/${specialty.get('name')}`, specialty);
    },
    [SpecialtiesActions.REMOVE_SPECIALTY]({dispatch}, specialty) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_SPECIALTIES}/${specialty.name}`).then(() => dispatch(SpecialtiesActions.LOAD_SPECIALTIES));
    },
  },
};
export default SpecialtiesModule