import ApiService from "@/core/services/ApiService";
import {MenusActions, MenusMutations} from "@/store/enums/MenusEnums";
import _ from 'lodash';

const MenusModule = {
  state: {
    menus: [],
    parentMenus: [],
    order: [],
    menu: {},
    errors: [],
  },
  mutations: {
    [MenusMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [MenusMutations.LIST_MENUS](state, menus) {
      state.menus = menus;
    },
    [MenusMutations.LIST_PARENT_MENUS](state, menus) {
      state.parentMenus = menus;
    },
    [MenusMutations.SHOW_MENU](state, menu) {
      state.menu = menu;
    },
    [MenusMutations.NEW_MENU](state, menu) {
      state.menu = menu;
    },
    [MenusMutations.ORDER_MENUS](state, items) {
      state.order = items;
    },
    [MenusMutations.DELETE_MENU](state, id) {
      state.menus = state.menus.filter((menu) => {
        return menu.id !== id
      });
    },
  },
  actions: {
    async [MenusActions.LOAD_MENU]({commit}, id, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_MENUS + `/${id}`, {})
        .then(({data}) => {
          commit(MenusMutations.SHOW_MENU, data);
        })
        .catch(({response}) => {
          commit(MenusMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [MenusActions.LOAD_MENUS]({commit}, filter= null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_MENUS, filter)
        .then(({data}) => {
          commit(MenusMutations.LIST_MENUS, data);
        })
        .catch(({response}) => {
          commit(MenusMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [MenusActions.LOAD_PARENT_MENUS]({commit}, payload = {}) {
      ApiService.setHeader();
      const params = _.merge(payload, {menu_id: 0});
      return await ApiService.get(process.env.VUE_APP_API_ENDPOINT_MENUS, params)
        .then(({data}) => {
          commit(MenusMutations.LIST_PARENT_MENUS, data);
          return data.data;
        })
        .catch(({response}) => {
          commit(MenusMutations.SET_ERRORS, response.data.errors);
        });
    },
    [MenusActions.INSERT_MENU](context, menu) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_MENUS}`, menu);
    },
    [MenusActions.UPDATE_MENU](context, menu) {
      ApiService.setHeader([{header: 'Content-Type', value: 'multipart/form-data'}]);
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_MENUS}/${menu.get('slug')}`, menu);
    },
    async [MenusActions.UPDATE_STATUS]({commit}, menu) {
      ApiService.setHeader();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_MENUS}/${menu.id}/status`, menu);
    },
    [MenusActions.REMOVE_MENU]({dispatch}, menu) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_MENUS}/${menu.slug}`).then(() => dispatch(MenusActions.LOAD_MENUS));
    },
    [MenusActions.REORDER_MENU]({dispatch}, ids) {
      ApiService.setHeader();
      return ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_MENUS}/reorder`, {items: ids});
    },
    [MenusActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
    [MenusActions.FILL_ORDER_MENU]({commit}, items) {
      commit(MenusMutations.ORDER_MENUS, items);
    },
  },
};
export default MenusModule