import Cookies from 'js-cookie';

const ID_TOKEN_KEY = "appToken";

/**
 * @description get token form localStorage
 */
export const getToken = () => {
    //return window.localStorage.getItem(ID_TOKEN_KEY);
    return Cookies.get(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token
 */
export const saveToken = (token) => {
    //window.localStorage.setItem(ID_TOKEN_KEY, token);
    Cookies.withAttributes({ sameSite: "lax" }).set(ID_TOKEN_KEY, token, { secure: true, sameSite: 'strict' });
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = () => {
    //window.localStorage.removeItem(ID_TOKEN_KEY);
    Cookies.remove(ID_TOKEN_KEY)
};

export default { getToken, saveToken, destroyToken };
