import {defineStore} from 'pinia'
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2";
import _ from "lodash";

export const useAuth = defineStore('auth', {
  state: () => ({
    auth: {},
    credentials: {},
    isAuthenticated: false,
    token: '',
  }),
  getters: {
    currentUser() {
      return this.auth
    },
    accessToken() {
      return this.token
    },
    check() {
      return this.token !== '' || this.token != null;
    },
  },
  actions: {
    can(role) {
      return !(_.isEmpty(role) || _.isEmpty(this.auth.roles?.roles[0]) || _.indexOf(role, this.auth.roles?.roles[0]) === -1);
    },
    async verifyAuth() {
      let token = this.token;
      if (!token) {
        this.purge();
        return false;
      }

      ApiService.setHeader();
      return await ApiService.post(process.env.VUE_APP_API_ENDPOINT_CHECK_TOKEN, {})
        .then(({data}) => {
          if (data.success !== true) {
            this.purge();
            return false;
          }
          this.auth = data.data;
          this.token = data.api_token
          return true;
        })
        .catch(({data}) => {
          this.purge();
          Swal.fire({
            title: 'Error',
            html: data.message,
            icon: 'error',
          }).then(() => {
            return false;
          });
        });
    },
    setAuth(data, token) {
      this.auth = data;
      this.token = token;
      this.isAuthenticated = true;
    },
    purge() {
      this.auth = {};
      this.token = '';
      this.isAuthenticated = false;
    },
    async login(payload) {
      return await ApiService.post(process.env.VUE_APP_API_ENDPOINT_LOGIN, payload).then(({data}) => {
        if (data.success === true) {
          this.setAuth(data.data, data.api_token)
          return data;
        }

        Swal.fire({
          title: 'Error',
          html: data.message,
          icon: 'error',
        });
        this.purge();
      }).catch(({response}) => {
        Swal.fire({
          title: 'Error',
          html: response.data.message,
          icon: 'error',
        });
        this.purge();
      });
    },
    async saveProfile() {
      ApiService.setHeader();
      return await ApiService.put(`/auth/profile`, this.auth)
        .then(({data}) => {
          if (data.success === true) {
            return data;
          }
          Swal.fire({
            title: 'Error',
            html: data.message,
            icon: 'error',
          });
        }).catch(({response}) => {
          if (typeof response.status == 'undefined') {
            return;
          }

          if (response.status == 404) {
            // @ts-ignore
            //this.$router.push({name: '404'});
          }
          catchErrorByStatus(response);
        });
    },
    async logout() {
      ApiService.setHeader();
      return ApiService.delete(process.env.VUE_APP_API_ENDPOINT_LOGOUT)
        .then(({data}) => {
          this.purge()
          return true;
        }).catch(({response}) => {
          if (typeof response.status == 'undefined') {
            return;
          }

          if (response.status == 404) {
            // @ts-ignore
            this.$router.push({name: '404'});
          }
          catchErrorByStatus(response);
        });
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'auth',
        storage: localStorage,
      },
    ],
  }
})
