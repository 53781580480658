const LocalizationsActions = Object.freeze({
  // action types
  INSERT_LOCALIZATION: "insertNewLocalization",
  UPDATE_LOCALIZATION: "updateLocalization",
  REMOVE_LOCALIZATION: "removeLocalization",
  LOAD_LOCALIZATION: "loadLocalization",
  LOAD_ALL_LOCALIZATIONS: "loadAllLocalizations",
  VIEW_LOCALIZATION: "showLocalization",
  SAVE_LOCALIZATION: "saveLocalization",
});
const LocalizationsMutations = Object.freeze({
  // action types
  NEW_LOCALIZATION: "newLocalization",
  EDIT_LOCALIZATION: "editLocalization",
  DELETE_LOCALIZATION: "deleteLocalization",
  SHOW_LOCALIZATION: "showLocalization",
  LIST_LOCALIZATIONS: "listLocalization",
  SET_ERRORS: "errors",
});

export { LocalizationsActions, LocalizationsMutations };
