const SpecialtiesActions = Object.freeze({
  // action types
  INSERT_SPECIALTY: "insertNewSpecialty",
  UPDATE_SPECIALTY: "updateSpecialty",
  UPDATE_CUSTOMER: "updateCustomer",
  UPDATE_STATUS: "updateSpecialtyStatus",
  SAVE_SPECIALTY: "saveSpecialty",
  REMOVE_SPECIALTY: "removeSpecialty",
  LOAD_SPECIALTIES: "loadSpecialties",
  LOAD_SPECIALTY: "loadSpecialty",
  VIEW_SPECIALTY: "showSpecialty",
  NOTIFY_ERRORS: "notifyErrors",
});
const SpecialtiesMutations = Object.freeze({
  // action types
  NEW_SPECIALTY: "newSpecialty",
  EDIT_SPECIALTY: "editSpecialty",
  DELETE_SPECIALTY: "deleteSpecialty",
  SHOW_SPECIALTY: "showSpecialty",
  LIST_SPECIALTIES: "listSpecialties",
  SET_ERRORS: "errors",
});

export { SpecialtiesActions, SpecialtiesMutations };
