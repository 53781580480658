import ApiService from "@/core/services/ApiService";
import {PartnersActions, PartnersMutations} from "@/store/enums/PartnersEnums";

const PartnersModule = {
  state: {
    partners: [],
    errors: [],
  },
  mutations: {
    [PartnersMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [PartnersMutations.LIST_PARTNERS](state, partners) {
      state.partners = partners;
    },
    [PartnersMutations.NEW_PARTNER](state, partner) {
      state.partner = partner;
    },
    [PartnersMutations.DELETE_PARTNER](state, id) {
      state.partners = state.partners.filter((partner) => {
        return partner.id !== id
      });
    },
  },
  actions: {
    async [PartnersActions.LOAD_PARTNERS]({commit}, filter=null) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_PARTNERS)
        .then(({data}) => {
          commit(PartnersMutations.LIST_PARTNERS, data);
        })
        .catch(({response}) => {
          commit(PartnersMutations.SET_ERRORS, response.data.errors);
        });
    },
    [PartnersActions.INSERT_PARTNER](context, partner) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_PARTNERS}`, partner);
    },
    [PartnersActions.SAVE_PARTNER]({dispatch}, partner) {
      if (partner.id > 0) {
        if (partner.action === 'update') {
          return dispatch(PartnersActions.UPDATE_PARTNER, partner);
        }
        return dispatch(PartnersActions.INSERT_PARTNER, partner);
      }
      return dispatch(PartnersActions.INSERT_PARTNER, partner);
    },
    async [PartnersActions.UPDATE_PARTNER]({commit}, partner) {
      ApiService.setHeader();
      //await ApiService.csrf();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_PARTNERS}/${partner.id}`, partner);
    },
    [PartnersActions.REMOVE_PARTNER]({dispatch}, partner) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_PARTNERS}/${partner.id}`).then(() => dispatch(PartnersActions.LOAD_PARTNERS));
    },
    [PartnersActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default PartnersModule