const StatusesActions = Object.freeze({
  // action types
  INSERT_STATUS: "insertNewStatus",
  UPDATE_STATUS: "updateStatus",
  REMOVE_STATUS: "removeStatus",
  LOAD_STATUS: "loadStatus",
  LOAD_ALL_STATUSES: "loadAllStatuses",
  VIEW_STATUS: "showStatus",
});
const StatusesMutations = Object.freeze({
  // action types
  NEW_STATUS: "newStatus",
  EDIT_STATUS: "editStatus",
  DELETE_STATUS: "deleteStatus",
  SHOW_STATUS: "showStatus",
  LIST_STATUSES: "listStatuses",
  SET_ERRORS: "errors",
});

export { StatusesActions, StatusesMutations };
