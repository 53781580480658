import ApiService from "@/core/services/ApiService";
import {InstallationsActions, InstallationsMutations} from "@/store/enums/InstallationsEnums";

const InstallationsModule = {
  state: {
    installations: [],
    errors: [],
  },
  mutations: {
    [InstallationsMutations.SET_ERROR](state, error) {
      state.errors = {...error};
    },
    [InstallationsMutations.LIST_INSTALLATION](state, installations) {
      state.installations = installations;
    },
    [InstallationsMutations.NEW_INSTALLATION](state, installation) {
      state.installation = installation;
    },
    [InstallationsMutations.DELETE_INSTALLATION](state, id) {
      state.installations = state.installations.filter((installation) => {
        return installation.id !== id
      });
    },
  },
  actions: {
    async [InstallationsActions.LOAD_INSTALLATION]({ commit }) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_INSTALLATIONS)
        .then(({data}) => {
          commit(InstallationsMutations.LIST_INSTALLATION, data);
        })
        .catch(({response}) => {
          commit(InstallationsMutations.SET_ERRORS, response.data.errors);
        });
    },
    async [InstallationsActions.LOAD_ALL_INSTALLATION]({ commit }) {
      ApiService.setHeader();
      await ApiService.get(process.env.VUE_APP_API_ENDPOINT_INSTALLATIONS, '?all=1')
        .then(({data}) => {
          commit(InstallationsMutations.LIST_INSTALLATION, data);
        })
        .catch(({response}) => {
          commit(InstallationsMutations.SET_ERRORS, response.data.errors);
        });
    },
    [InstallationsActions.INSERT_INSTALLATION](context, installation) {
      ApiService.setHeader();
      return ApiService.post(`${process.env.VUE_APP_API_ENDPOINT_INSTALLATIONS}`, installation);
    },
    [InstallationsActions.SAVE_INSTALLATION]({dispatch}, installation) {
      if (installation.id > 0) {
        if (installation.action === 'update') {
          return dispatch(InstallationsActions.UPDATE_INSTALLATION, installation);
        }
        return dispatch(InstallationsActions.INSERT_INSTALLATION, installation);
      }
      return dispatch(InstallationsActions.INSERT_INSTALLATION, installation);
    },
    async [InstallationsActions.UPDATE_INSTALLATION]({commit}, installation) {
      ApiService.setHeader();
      //await ApiService.csrf();
      return await ApiService.put(`${process.env.VUE_APP_API_ENDPOINT_INSTALLATIONS}/${installation.id}`, installation);
    },
    [InstallationsActions.REMOVE_INSTALLATION]({dispatch}, installation) {
      ApiService.setHeader();
      return ApiService.delete(`${process.env.VUE_APP_API_ENDPOINT_INSTALLATIONS}/${installation.id}`).then(() => dispatch(InstallationsActions.LOAD_INSTALLATION));
    },
    [InstallationsActions.NOTIFY_ERRORS]({dispatch}, error) {

    },
  },
};
export default InstallationsModule